section.product-listing-pod + section.product-listing-full-pod{
    padding: 0;
    @include max-tablet() {
        padding: 0 30px 60px 30px;
    }
}
section.relevant-product-listing-pod + section.geo-location-pod{
    padding: 40px 0 90px 0;
    @include max-tablet() {
        padding: 40px 30px 90px 30px;
    }
}
section.product-listing-full-pod,
section.relevant-product-listing-pod{
    padding: 60px 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    @include max-tablet() {
        padding-left: 30px;
        padding-right: 30px;
    }

    p.head-content {
        @include font-size(20,26);
        margin-bottom: 35px;
    }
    span.caption {
        @include font-size(14,26);
        text-align: right;
        font-style: italic;
        display: inline-block;
        width: 100%;
        padding-right: 15px;
    }

    .filter-area {
        margin-bottom: 20px;
        text-align: center;

        &.mobile-show {
            margin-bottom: 0;
            @include mobile-show();

            a {
                margin: 60px auto 0 auto;
                display: block;
                float: none;
            }
        }

        a {
            &.mobile-hide {
                @include mobile-hide();

            }
        }

        .span10 {
            @include max-tablet() {
                width: 97.5%;
                margin: 0 1.25%;
            }
            @include max-mobile() {
                margin: 0 1.25%;
            }
        }

        h2, a {
            display: inline-block;
        }

        h2 {
            float: left;
            @include max-mobile() {
                width:100%;
                display: inline-block;
            }
        }

        label {
            float: right;
            @include max-mobile() {
                width:100%;
                display: inline-block;
            }

            .select-wrapper{
                @include max-mobile() {
                    margin-left: 0;
                    margin-top: 10px;
                }
                @include max-mobile() {
                .chosen-container {
                    width: 100% !important;
                }
                }
            }
        }

        a {
            float: right;
        }
    }

    .span10 {
        @include max-tablet() {
            width: 100%;
            margin: 0;
        }

        @include max-mobile() {
            margin: 0 1.25%;
        }

        .span4 {
            @include flex();
            @include flex-wrap(wrap);
            margin-bottom: 40px;
            background-color: #ffffff;

            @include max-tablet() {
                width: 47.5%;
            }

            @include max-mobile() {
                width: 97.5%;
                margin-left: 0;
            }

            &:nth-child(n):nth-child(3n+3) {
                @include min-desktop() {
                    margin-right: 0;
                    margin-left: 2.5%;
                }
            }

            &:nth-child(3n+1) {
                @include min-desktop() {
                    margin-left: 0;
                    margin-right: 2.5%;
                }
            }

            //&:nth-child(-n+2) {
            //    @include min-desktop() {
            //        margin-right: 2.5%;
            //        margin-left: 0;
            //    }
            //}

            div.img-container {
                position: relative;
                text-align: center;
                background-color: #efefef;
                width: 100%;
                height: 200px;
                @include max-tablet() {
                    width: 100%;
                }
                a {
                    width: 100%;
                    height: 100%;
                    //display: inline-block;
                    text-align: center;
                    @include flex();
                    @include flex-align(center);

                    html.no-flexboxlegacy & {
                        display: inline-block;
                    }

                    img.main {
                        display: inline-block;
                        vertical-align: middle;
                        max-height: 200px;
                        margin: 0 auto;
                        @include max-tablet() {
                            width: 100%;
                        }
                        @include max-mobile() {
                            width: auto;
                            max-height: 100%;

                        }

                        html.no-flexboxlegacy & {
                            position: absolute;
                            top: 50%;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            transform: translate(0%, -50%);
                        }
                    }

                    span.inline-promo {
                        display: inline-block;
                        background-color:#e1261c;
                        padding: 7px 7px;
                        color: $white;
                        @include font-size(18,18);
                        font-weight: 300;
                        font-family: $franklin-book;
                        text-transform: uppercase;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        .now {
                            font-family: $franklin-dm;

                        }

                        .was {
                            font-family: $franklin-book;
                            text-decoration: line-through;
                        }
                    }
                }
            }

            .content-block {
                padding: 20px;
                width: 100%;
                text-align: center;
                @include flex();
                @include flex-wrap(wrap);
                @include max-mobile() {
                    width: 100%;
                }

                h4 {
                    margin-top: 15px;
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                }

                h4 a {

                    text-align: center;
                    margin-bottom:15px;
                    width: 100%;
                    color: #06102f;
                    text-decoration: none;
                }

                a {
                    align-self: flex-end;

                    margin-top: auto;
                    margin-left: auto;
                    margin-right: auto;
                }

            }
        }
    }

    @include max-tablet(){
        padding: 50px 30px;
    }

    @include max-mobile(){
        padding: 50px 30px;
    }

    [class^=span]{
        //@include max-tablet() {
        //    width:47.5%;
        //}
        //
        //@include max-mobile(){
        //    width:97.5%;
        //}

        &.span3 {
            @include max-tablet() {
                margin-left: 0;
            }

            @include max-mobile() {
                display: none;
            }
        }
    }

    .span12 {
        text-align: center;
        .meta-pagination {
            padding: 12px 50px 52px;

            @include max-mobile(){
                padding: 12px 0px 52px;
            }
        }
    }
}

section.relevant-product-listing-pod {
    .span10 {
        .span4 {
            margin-bottom: 0;

            @include max-tablet(){
                margin-bottom: 60px;
            }

            &:last-child {
                @include max-tablet() {
                    margin-bottom: 0;
                }
            }

            .content-block {
                padding: 0 20px 0 20px;

                &.no-paragraph{
                    padding: 20px 20px 0 20px;
                    h4 {
                        margin-bottom:25px;
                    }
                }
            }
        }

    }
}