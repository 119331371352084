/*** Global and page-specific form style ***/

.standard-form,
.wffm-form {
	&:after,
	form:after {
		@include cf();
	}

	span {
		@include font-size(16,24);
		display: block;
		margin-bottom: 5px;
	}

	.button {
		line-height: normal;
	}

	input,
	textarea {
		background-color: $grey-1;
	}

	input {
		max-height: 50px;
	}

	input,
	textarea,
	.select-wrapper,
	.checkbox-wrapper,
	.radio-wrapper,
	button {
		margin-top: 30px;
	}

	@include small-desktop() {
		[class^=span] {
			&.narrow {
				width: (percentage((6*$column+10*$margin)/$max-width));
			}

			&.text-right {
				text-align: left;
			}
		}
	}

	@include max-mobile() {
		input,
		textarea,
		.select-wrapper,
		button,
		.radio-wrapper,
		.checkbox-wrapper {
			margin-bottom: 0;
			margin-top: 15px;
		}

		[class^=span] {
			&.narrow {
				width: (percentage((12*$column+22*$margin)/$max-width));
			}
		}

		button {
			width: 100%;
			text-align: center;
		}
	}

	.g-recaptcha {
		margin-top: 2rem;
	}
}

.rich-text {
	.standard-form {
		margin-left: (-(percentage($margin/$max-width)));
		margin-right: (-(percentage($margin/$max-width)));
	}
}

fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

label {
	display: block;
	margin-bottom: 5px;
	color: $text-grey;

	&.hidden {
		visibility: hidden;
		height: 0 !important;
		width: 0 !important;
		font-size: 0 !important;
		margin: 0 !important; // override custom styles
	}
}

button {
	border: none;
}

input {
	max-height: 50px;
}

input,
textarea {
	display: block;
	width: 100%;
	padding: 16px 20px 15px 20px;
	border: none;
	@include border-radius(5px);
	@include placeholder-color($text-grey);
	color: $text-grey;
	line-height: 18px;

	&:focus {
		outline: 0;
	}

	&[type=radio],
	&[type=checkbox] {
		height: auto;
	}

	&[type=submit] {
		width: auto;
		border: none;
	}
}

input,
textarea,
.select-wrapper,
button,
.radio-wrapper,
.checkbox-wrapper {
	@include max-mobile() {
		margin-bottom: 15px;
	}

	&:focus {
		outline: 0;
	}
}

textarea {
	width: 100%;
	min-height: 150px;
}

.select-wrapper,
select {
	width: 100%;
}

select {
	padding: 8px;
	cursor: pointer;

	&:focus {
		outline: 0;
	}
}

html.safari-mac {
	.select-wrapper {
		select {
			-webkit-appearance: none;
			padding: 13px 15px 14px 15px;
		}
	}
}

.select-wrapper {
	position: relative;

	// Included in mark-up for accessibility
	label {
		visibility: hidden;
		height: 0;
		margin: 0;
	}

	select {
		padding: 15px;
		border: 1px solid $grey-4;
		@include border-radius(5px);
		color: $text-grey;
		max-height: 50px;
		background-color: $white;
		-webkit-appearance: none;
		line-height: 18px;
	}

	.select-arrow {
		position: absolute;
		right: 1px; /* for border */
		top: 1px; /* for border */
		width: 35px;
		height: (calc(100% - 2px)); /* height of select-wrapper, minus 2 for border */
		background-color: $white;
		@include border-radius(5px);
		pointer-events: none;

		&:before {
			content: '';
			position: absolute;
			width: 6px;
			height: 6px;
			display: inline-block;
			right: 15px;
			top: 18px;
			border-bottom: 1px solid $text-grey-light;
			border-right: 1px solid $text-grey-light;
			@include rotate(45deg);
		}
	}

	// <= IE10
	html.no-webgl.no-flexboxlegacy & {
		.select-arrow {
			display: none;
		}
	}
}

input[type=radio],
input[type=checkbox] {
	position: absolute;
	left: -9999px;
}

.radio-wrapper,
.checkbox-wrapper,
.checkbox-content {
	label {
		position: relative;
		padding-left: 30px;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none;   /* Chrome/Safari/Opera */
		-khtml-user-select: none;    /* Konqueror */
		-moz-user-select: none;      /* Firefox */
		-ms-user-select: none;       /* Internet Explorer/Edge */
		user-select: none;  
		font-size: 16px;
		line-height: 20px;
		cursor: pointer;

		&:before {
			content: '';
			position: absolute;
			width: 40px;
			height: 40px;
			top: 0;
			left: 0;
			background-color: $grey-1;
			@include box-sizing(border-box);
		}
	}

	// Inline styles
	&.inline {
		label {
			display: inline-block;
			margin-right: 30px;
		}
	}
}

.radio-wrapper {
	label {
		padding-left: 42px;
		min-height: 34px;
		line-height: 34px;
		
		&:before {
			width: 34px;
			height: 34px;
			@include border-radius(100%);
		}
	}

	input[type=radio]:checked + label,
	input[type=radio]:checked + input + label {
		&:before {
			background-color: $group-green;
			border: 8px solid $grey-1;
		}
	}
}

.checkbox-wrapper,
.checkbox-content {
	label {
		padding-left: 47px;
		min-height: 40px;
		
		&:before {
			content: '';
			color: $white;
			@include border-radius(5px);
		}
	}

	input[type=checkbox]:checked + label,
	input[type=checkbox]:checked + input + label {
		&:before {
			background-image: url(../images/icons/Checked.png);
			background-size: 15px auto;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}

.input-validation-error {
	/*@include box-shadow(0, 0, 0, 1px, $red);*/
	border: 1px solid $red !important; // override default border
	background-image: url(../images/icons/Warning_Icon.png);
	background-size: 14px auto;
	background-position: right 20px center;
	background-repeat: no-repeat;
}
select.input-validation-error {
	@include box-shadow-none();
	border-color: $red;
	background-position: right 36px center;
}
textarea.input-validation-error {
	background-position: right 20px top 18px;
}

.search-icon {
	background-color: transparent;
	background-image: url(../images/icons/Search_Icon.png);
	background-position: right center;
	background-repeat: no-repeat;
	background-size: 21px;
	height: 21px;
	width: 21px;
	cursor: pointer;
	position: relative;
	z-index: 2;
	float: right;

	&.active {
		background-image: none;

		&:before {
			content: "\e902";
			font-family: IcoMoon;
			color: $white;
			@include font-size(21);
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.search-form {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;

	@include mobile-nav() {
		margin-left: 0;
		width: 100%;
	}

	// Included in mark-up for accessibility
	label {
		visibility: hidden;
		height: 0;
		width: 0;
		margin: 0;
	}

	fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}

	input {
		float: right;
		background-color: $grey-1;
		background-image: url(../images/icons/Search_Icon.png);
		background-position: right center;
		background-repeat: no-repeat;
		background-size: 30px;
		font-size: 16px;
		font-family: $CircularBold;
		height: 30px;
		min-height: 0;
		line-height: 30px !important;
		padding-top: 0;
		padding-left: 0;
		padding-bottom: 0;
		padding-right: 30px;
		border: none;
		width: 30px;
		cursor: pointer;
		@include transition(all 0.3s ease);
		color: $text-grey-dark;
		position: relative;
		z-index: 2;
		@include border-radius(30px);

		&:focus {
			width: 225px;
			border-left: none;
			color: $text-grey-dark;
			z-index: 0;
			padding-left: 13px;
		}

		@include mobile-nav() {
			font-size: 16px;
			width: 100%;
			padding-left: 13px;
			margin-bottom: 5px;
			background-color: $white;
			color: $text-grey-light; 

			&:focus {
				color: $text-grey-light;
				width: 100%;
			}
		}
	}

	input[type=submit] {
        position: absolute;
        width: 30px;
        height: 30px;
        opacity: 0;
        top: 0;
        right: 0;
        padding: 0;
        margin: 0;
        border: none;
        z-index: 1;
		@include transition(opacity 0.2s ease);

		&:hover {
			opacity: 0.7;
		}
    }

	&:after {
		@include cf();
	}
}

[class^=validation-summary] {
	ul {
		margin-bottom: 0;
	}
}

// Sign Up form
.sign-up {
	width: 100%;

	&.full-width {
		background-color: $grey-1;
	}

	@include max-desktop() {
		padding-right: $tablet-mobile-padding;
		padding-left: $tablet-mobile-padding;
	}
	
	.row {
		background-color: $grey-1;
		padding-top: 60px;
		padding-bottom: 60px;
	}

	// IE11 fix
	html.no-flexboxlegacy & {
		fieldset {
			border: 1px solid transparent;
		}
	}

	.flex {
		@include flex();
		@include flex-wrap(wrap);
		@include flex-justify(center);
	}

	h2,p {
		font-family: $CircularBold;
	}

	h2 {
		color: $red;
		margin-bottom: 10px;
	}

	p {
		color: $text-grey;
		font-size: 1.5rem;
		margin-bottom: 0;
	}

	input,
	textarea,
	.select-wrapper,
	.checkbox-wrapper,
	.radio-wrapper,
	button {
		margin-top: 20px;
	}

	input {
		background-color: $white;
		border: 1px solid $white;
	}

	.checkbox-wrapper {
		vertical-align: top; 

		label {
			cursor: pointer;
			top: 5px;
			line-height: 40px;
			margin: 0;

			&:before {
				background-color: $white;
			}
		}

		input[type=checkbox]:checked + label,
		input[type=checkbox]:checked + input + label {
			&:before {
				color: $group-green;
			}
		}
	}

	.button {
		padding: 16px 20px 15px 20px;
		margin-left: 35px;
	}

	@include min-max-tablet() {
		.input-area {
			width: (percentage((9*$column+16*$margin)/$max-width));
			margin-bottom: 10px;
		}
	}

	@include max-tablet() {
		.sign-up-form {
			padding-top: 30px;
			padding-bottom: 30px;
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	@include max-mobile() {
		h2 {
			line-height: 28px;
		}

		.input-area {
			width: (percentage((12*$column+22*$margin)/$max-width));
		}

		.checkbox-wrapper {
			display: block;
		}

		.button {
			margin-left: 0;
			margin-top: 22px;
			margin-bottom: 0;
			width: 100%;
		}
	}
}

// Flatpickr styles
.flatpickr-input {
	line-height: 27px;
}
.flatpickr-current-month {
	font-weight: normal;

	.cur_month,
	.cur_year {
		color: $group-green;
		font-weight: normal;		
		font-family: $CircularBold;
	}
}
.flatpickr-month {
	padding: 10px;
}
.flatpickr-next-month,
.flatpickr-prev-month {
	svg {
		top: 15px;

		&:hover {
			fill: $group-green;
		}
	}
}
.flatpickr-day {
	color: $text-grey;

	&:focus,
	&:hover,
	&.nextMonthDay,
	&.prevMonthDay {
		&:focus,
		&:hover {
			background-color: $grey-2;
			border-color: $grey-2;
		}
	}

	&.today {
		border-color: $group-green;

		&:focus,
		&:hover {
			background-color: $group-green;
			border-color: $group-green;
		}
	}

	&.selected {
		background-color: $group-green !important;
		border-color: $group-green !important;
	}
}
.flatpickr-time input,
.flatpickr-am-pm {
	color: $group-green;
	font-weight: normal;
}