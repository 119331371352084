/*** LANDING PAGE SPECIFIC STYLES ***/

body.landing-page {
    overflow-x: hidden;
    
    /* Header */
    .page-header {
        padding: 20px 0;
        #logo {
            img {
                // max-width: 195px;
                // image-rendering: -webkit-optimize-contrast;
            }
        }

        .text-right.costcutter {
            padding-top: 5px;
        }
    }

    /* Store locator */
    .store-finder-area {
        padding-bottom: 50px;
    }

    section.final {
        .pod {
            &.cross-site {
                .bg-image {
                    background-size: auto 100%;
                }
            }
        }
    }

    /* Slider */
    .slick-slider { 
        .slide-type-a {
            > .bg-image {
                height: 100%;
            }
        }
    }
}

.featured-in-recipe {
    background-color: $grey-1;

    .row {
        padding: 20px 0;
        @include flex();
        @include flex-wrap(wrap);
        @include flex-align(center);

        .cutout {
            position: absolute;
            width: 59px;
            top: -18px;
            height: 20px;
            left: 0;
            background-image: url(../images/Triangle_Cutout.png);
            background-position: left top;
            background-repeat: no-repeat;
            background-size: auto;

            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: $grey-1;
                height: 20px;
                top: 0;
            }

            &:before {
                left: -1400px;
                width: 1400px;
            }

            &:after {
                right: -1920px;
                width: 1920px;
            }
        }
    }

    .text {
        padding-right: 50px;
    }

    .title {
        display: block;
        color: $red;
        font-family: $CircularBold;
        @include font-size(36);
        margin-bottom: 15px;
    }

    p {
        color: $text-grey;
        margin-bottom: 25px;
    }

    @include max-desktop() {
        padding-left: 15px;
        padding-right: 15px;
    }

     @include min-desktop() {
         .row {
            @include flex-direction(row-reverse);
         }
    }

    @include max-tablet() {
        .row {
            [class^=span] {
                padding: 20px 0;
                width: (percentage((12*$column+22*$margin)/$max-width));

                &.image {
                    text-align: center;
                }
            }
        }
    }
}

.landing-page-content {
    background-repeat: repeat;
    background-size: auto;
    background-position: top center;
    padding-top: 80px;
    padding-bottom: 80px;

    .container {
        margin-top: 70px;
    }

    @include max-desktop() {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include max-tablet() {
        padding-top: 50px;

        .row {
            [class^=span] {
                &.content {
                    width: (percentage((12*$column+22*$margin)/$max-width));
                    margin-left: (percentage($margin/$max-width));
                }
            }
        }
    }

    @include max-mobile() {
        padding-top: 30px;
    }
}

.landing-offers {
    padding-bottom: 70px;

    .row {
        @include flex();
    }

    .item {
        margin-bottom: 30px;

        img {
            display: block;
            margin: 0 auto;
        }

        .product-title {
            display: block;
            padding-top: 23px;
            border-top: 2px solid $group-green;
            width: 100%;
            color: $red;
            font-family: $CircularBold;
            @include font-size(30);
        }
    }

    .button {
        margin-top: 40px;
    }
    
    @include min-max-tablet() {
        .row {
            .item {
                width: (percentage((6*$column+10*$margin)/$max-width));

                &:nth-child(3),
                &:nth-child(4) {
                    display: none;
                }
            }
        }
    }

    @include max-mobile() {
        .row {
            @include flex-wrap(wrap);

            .item {
                width: (percentage((12*$column+22*$margin)/$max-width));

                &:last-child {
                    display: none;
                }

                .product-title {
                    text-align: center;
                }
            }
        }
    }
}

.social-area {
    padding-top: 50px;

    .rich-text {
        margin-bottom: 30px;

        h2 {
            @include font-size(34);
        }
    }

    @include max-desktop() {
        padding-left: 15px;
        padding-right: 15px;
    }

    @include max-tablet() {
        padding-top: 30px;
    }
}

/* JUICER Styles */
.juicer-feed {
    background-color: $grey-1;           

    .j-paginate {
        color: $red !important; /* override embed default styles */
        border-color: $red !important; /* override embed default styles */

        &:hover {
            background-color: $red !important; /* override embed default styles */
            color: $white !important; /* override embed default styles */
        }
    }
}

.j-stacker-wrapper {
    padding: 15px;
}