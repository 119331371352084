section.story-listing-full-pod {
    padding: 45px 0 30px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f7323f;

    @include max-tablet() {
        padding: 45px 30px 0;
    }

    @include max-mobile() {
        padding: 45px 15px 0;
    }

    .span12 {
        text-align: center;
        h2 {
            color: $white;
            @include font-size(32,36);
            font-family: $CircularBold;
            margin-bottom: 35px;
            letter-spacing: -2px;
        }
    }

    .span4 {
        @include flex();
        @include flex-wrap(wrap);
        margin-bottom: 30px;
        background-color: #ffffff;

        @include max-tablet() {
            width: 47.5%;
        }

        @include max-mobile() {
            width: 97.5%;
        }

        a.img {
            width: 100%;
            img.main {
                width: 100%;
                @include max-mobile() {
                    width: 100%;
                }
            }
        }

        .content-block {
            padding: 20px 20px 40px 20px;
            width: 100%;
            text-align: left;
           @include flex();
           @include flex-wrap(wrap);
            @include max-mobile() {
                width: 100%;
            }

            h2 a {
                text-align: center;
                margin: 0 0 15px 0;
                text-decoration: none;
                color: #f7323f;
                font-family: $CircularBold;
            }

            h2 {
                width: 100%;
                margin: 0 0 15px 0;
                font-family: $CircularBold;
                letter-spacing: -0.5px;
            }

            a {
                align-self: flex-end;

                margin-top: auto;
                margin-right: auto;
            }


            p {

                width: 100%;
                color: #737373;
                 margin: 0 0 25px;
                font-family: $CircularBook;
            }

            [class^=span] {
                @include max-tablet() {
                    width: 47.5%;
                }

                @include max-mobile() {
                    width: 97.5%;
                }
                &.span7 {
                    // height: 100%;
                    background-repeat: no-repeat;
                    background-size: cover;
                    margin-left: 0;
                    @include flex();
                    @include flex-wrap(wrap);
                }


                //&.span5 {
                //    padding: 30px 0;
                //    @include max-mobile() {
                //        display: none;
                //    }
                //}
            }
        }
    }

    .meta-pagination {
          padding: 15px 0 45px;
      }
}