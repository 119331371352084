.rich-text {

	> * {
		&:last-child {
			margin-bottom: 0;
		}
	}

	.left-float {
		margin: 0 10px 10px 0;
	}

	.right-float {
		margin: 0 0 10px 10px;
	}

	.video-pod {
		margin: 0 0 20px 0;
	}

	h2,h3,h4 {
		font-family: $CircularBold;
	}

	h2 {
		@include font-size(50);
		color: $red;
		letter-spacing: -2px;
	}

	h4 {

		@include font-size(18, 24);
		color: $red;

		&.center {
			text-align: center;
		}
	}

	h3 {
		@include font-size(22);
		color: $group-green;
		margin-top: 30px;

		@include max-mobile() {
			margin-top: 20px;
		}
	}

	p {
		color: $text-grey;
		margin-bottom: 20px;
		@include font-size(16,26);

		img {
			width: 100%;
			display: inline-block;
			margin: 20px 0;
		}
	}

	p,
	ul li,
	ol li {
		a {
			color: inherit;
			text-decoration: underline;
			@include transition(color 0.2s ease);

			&:hover {
				color: $red;
			}
		}
	}

	img {
		margin-bottom: 1rem;
	}

	table {
		width: 100%;
		display: block;
		overflow: auto;
		overflow-y: hidden;
		overflow-x: auto;
		border-collapse: collapse;
		margin: 30px 0;

		th,td {
			padding: 10px 15px;
		}

		thead th {
			text-align: left;
			background-color: $grey-2;
			font-weight: bold;
		}

		tbody {
			tr {
				&:nth-child(odd) {
					background-color: $grey-1;
				}

				&:nth-child(even) {

				}
			}
		}
	}

	ul {
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	ol {
		list-style: none;
		counter-reset: list;
		padding-left: 0;

		li {
			counter-increment: list;
			padding-left: 25px;

			&:before {
				content: counter(list) ".";
				position: absolute;
				top: 0;
				left: 0;
				@include font-size(16,26);
			}
		}
	}

	li {
		color: $text-grey;
		position: relative;
		padding-left: 18px;
		margin-bottom: 16px;
		@include font-size(16,26);

		a {
			color: inherit;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	ul li {

		&:before {
			content: '\00b7';
			position: absolute;
			left: -2px;
			top: -2px;
			font-size: 50px;
		}
	}

	&.recipe-rich-text {
		ul {
			margin-bottom: 40px;

			li {
				padding: 10px 15px;
				margin: 0;

				&:before {
					display: none;
				}

				&:nth-child(odd) {
					background-color: $grey-6;
				}
			}
		}

		ol {
			li {
				margin-bottom: 25px;
			}
		}
	}
}

/* TODO: Add blockquote styling */
blockquote {
	position: relative;

	&:before,
	&:after {
		content: '\0201C';
		position: absolute;
		font-style: normal;
		top: 0;
		left: 0;
	}

	&:after {
		content: '\0201D';
	}

	&:after {
		top: auto;
		left: auto;
		bottom: 0;
		right: 0;
	}
}

@include max-mobile() {
	.rich-text {
		h2 {
			@include font-size(30);
		}
	}
}