/*** GENERAL Content Styles ***/

section {
	position: relative;
	overflow: hidden;
}

section.container {
	padding-top: 60px;
	padding-bottom: 10px;
	overflow: hidden;

	.content {
		article {
			margin-bottom: 30px;
		}
	}

	&.final {
		padding-top: 0;
		padding-bottom: 0;
	}

	&.no-image-pod {
		padding-bottom: 60px;
	}

	&.offer-container {
		padding-bottom: 80px;
	}

	&.pb60 {
		padding-bottom: 60px;
	}

	&.sitemap {
		padding-top: 0;
	}

	@include min-desktop() {
		.narrow-column {
			float: right;
		}
	}

	@include max-desktop() {
		padding-left: $tablet-mobile-padding;
		padding-right: $tablet-mobile-padding;
	}

	@include max-tablet() {
		.content,
		.narrow-column,
		&.main-content > .row > [class^=span] {
			width: (percentage((12*$column+22*$margin)/$max-width));
			margin-left: 1.25%;
		}

		&.pb60 {
			padding-bottom: 40px;
		}
	}

	@include max-mobile() {
		padding-top: 30px;
		padding-bottom: 0;

		&.pb60 {
			padding-bottom: 20px;
		}
	}
}

.page-wrapper {
	&.generic {
		h1 {
			font-family: $CircularBold;
			@include font-size(50);
			color: $red;
			letter-spacing: -2px;
		}
	}
}

// Addthis area
.page-share {
	width: 100%;
	font-size: 0;
	border-top: 1px solid $grey-3;
	padding-top: 20px;
	margin-top: 40px;

	div {
		display: inline-block;
	}

	a {
		text-decoration: none;
		vertical-align: top;
		display: inline-block;
		width: 30px !important; // override addthis inline styles
		height: 30px;
		margin-right: 4px;
		background-size: 30px;
		background-repeat: no-repeat;
		background-position: center;
		@include transition(opacity 0.2s ease);

		svg, > span {
			display: none;
		}

		&:hover {
			opacity: 0.7;
		}

		&.instagram {
			background-image: url(../images/icons/Instagram.png);
		}

		&.google {
			background-image: url(../images/icons/Instagram.png);
		}

		&.twitter {
			background-image: url(../images/icons/Twitter.png);
		}

		&.facebook {
			background-image: url(../images/icons/Facebook.png);
		}
	}

	h2 {
		display: inline-block;
		vertical-align: top;
		line-height: 30px;
		font-size: 18px;
		margin-left: 10px;
		color: $text-grey;
		font-family: $CircularBold;
	}
}
// Mobile styles
@include max-mobile() {
	.page-share {
		margin-top: 10px;
	}
}

/*** EQUALISE area ***/
.equalise {
	.row {
		// The following applies display: flex; to the row. This means all spans below it will have equal heights.
		@include flex();

		// The following applied align-items: center; to the row. This means the content of the spans will be vertically centred.
		@include flex-align(center);

		// If the span contains buttons and they need to be aligned to the bottom:
		.button {
			position: absolute;
			bottom: 0;
		}
		[class^=span] {
			// The padding-bottom is based on the size of the bottom. Ideally the text within the button would be kept to a minimum.
			padding-bottom: 45px;

			// IE9 Fix
			html.no-flexbox & {
				padding-bottom: 45px;
			}
		}
	}
}