/*** BUTTON Styles ***/

.button {
	display: inline-block;
	position: relative;
	padding: 14px 20px 16px 20px;
	color: $white;
	font-family: $CircularBold;
	background-color: $red;
	@include border-radius(5px);
	text-decoration: none;
	max-height: 50px;

	&:after {
		content: '';
		position: relative;
		display: inline-block;
		width: 6px;
		height: 6px;
		border-top: 1px solid $white;
		border-right: 1px solid $white;
		margin-left: 5px;
		top: -1px;
		@include rotate(45deg); 
	}

	@include max-mobile() {
		padding: 10px 16px;
		@include font-size(14); 
	}

	/* hover states not yet implemented so using a standard one for now */
	//&:hover {
	//	text-decoration: underline;
	//}

	&.short {
		padding: 8px 13px;

		&:before {
			right: 16px;
		}

		&.clear {
			padding-top: 7px;
			padding-bottom: 7px;
		}
	}

	&.large {
		padding: 14px 32px 14px 16px;

		&:before {
			right: 17px;
		}

		&.wide {
			padding: 14px 54px 14px 42px;

			&:before {
				right: 40px;
			}
		}
	}

	&.green {
		background-color: $group-green;
	}

	&.red {
		background-color: $red;
	}

	&.clear {
		background-color: transparent;
		border: 1px solid $white;
	}
}

//hover states

.button.green {
	background: linear-gradient(to right, $group-green-darker 50%, $group-green 50%);
	@include transition(all 0.3s ease);
	transform-origin: left;
	background-size: 200% 100%;
	background-position:right bottom;

	&:hover {
		background-color: $group-green-darker;
		color: $white;
		background-position:left bottom;
	}
}

.button.clear {
	background: linear-gradient(to right, white 50%, transparent 50%);
	@include transition(all 0.3s ease);
	transform-origin: left;
	background-size: 200% 100%;
	background-position: right bottom;
	
	&:hover {
		background-color: white;
		color: $text-grey;
		background-position: left bottom;
		
		&:after {
			border-color: $text-grey;
		}
	}

	&.red {
		background: linear-gradient(to right, $red 50%, transparent 50%);
		background-size: 200% 100%;
		background-position: right bottom;
		color: $red;
		border-color: $red;

		&:after {
			border-color: $red;
		}

		&:hover {
			background-color: $red;
			color: $white;
			background-position: left bottom;
			
			&:after {
				border-color: $white;
			}
		}
	}

	&.green {
		background: linear-gradient(to right, $group-green 50%, transparent 50%);
		background-size: 200% 100%;
		background-position: right bottom;
		color: $group-green;
		border-color: $group-green;

		&:after {
			border-color: $group-green;
		}

		&:hover {
			background-color: $group-green;
			color: $white;
			background-position: left bottom;
			
			&:after {
				border-color: $white;
			}
		}
	}
}

.button.red {
	background: linear-gradient(to right, $red-2 50%, $red 50%);
	@include transition(all 0.3s ease);
	transform-origin: left;
	background-size: 200% 100%;
	background-position:right bottom;

	&:hover {
		background-color: $red-2;
		@include transition(all 0.3s ease);
		background-position:left bottom;

		&:after {
			border-color: $white;
		}
	}
}