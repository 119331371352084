.search-results {
	margin-top: 40px;

	@include max-desktop() {
		padding-left: $tablet-mobile-padding;
		padding-right: $tablet-mobile-padding;
	}

	@include small-desktop() {
		.row {
			[class^=span] {
				width: (percentage((12*$column+22*$margin)/$max-width));
			}
		}
	}

	h2 {
		margin-bottom: 0;
	}
	
	.category-filter {
		margin: 15px 0;
		font-size: 0;
		
		.refine,select {
			font-size: 16px;
			line-height: 20px;
			display: inline-block;
			vertical-align: middle;
			color: $text-grey-lighter;
		}

		.refine {
			margin-right: 15px;
			font-size: 20px;
			color: $group-green;
			font-family: $CircularBold;

			@include max-mobile() {
				display: block;
				margin-bottom: 10px;
			}
		}

		.select-wrapper {
			display: inline-block;
			vertical-align: middle;
			max-width: 250px;
			
			@include max-mobile() {
				margin-bottom: 0;
			}
		}
	}

	.pages {
		display: block;
		color: $text-grey-lighter;
	}

	.search-results-item {
		width: 100%;
		padding: 20px 0;
		border-bottom: 1px solid $grey-3;
		position: relative;

		@include max-mobile() {
			padding-top: 25px;
			padding-bottom: 30px;
		}

		.title {
			margin-top: 0;
		}

		.title,
		.summary {
			padding-right: 150px;

			@include max-mobile() {
				padding-right: 0;
			}
		}

		.summary {
			position: relative;
		}

		.title,p {
			letter-spacing: -0.5px;			
		}

		p {
			margin-bottom: 0;
			color: $text-grey-lighter;
		}

		.button {
			position: absolute;
			top: 50%;
			margin-top: -19px;
			right: 0;
			padding: 7px 13px 7px 10px;
		
			@include max-mobile() {
				position: relative;
				top: auto;
				right: auto;
				margin-top: 20px;
			}
		}
	}
}