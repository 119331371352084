/* Transition and animation mixins */
@mixin transition($transition) {
	-webkit-transition: $transition;
	-moz-transition:    $transition;
	-ms-transition:     $transition;
	-o-transition:      $transition;
	transition:			$transition;
}

@function em($px, $base: $base-font-size) {
    @return ($px / $base) * 1em;
}

@function calculateRem($px, $base: $base-font-size) {
	$remSize: $px / $base;
	@return #{$remSize}rem;
}

@mixin transition-property($transition-property) {
	-webkit-transition-property: $transition-property;
	-moz-transition-property:    $transition-property;
	-ms-transition-property:     $transition-property;
	-o-transition-property:      $transition-property;
	transition-property:		 $transition-property;
}

@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
	-moz-transition-delay:    $transition-delay;
	-ms-transition-delay:     $transition-delay;
	-o-transition-delay:      $transition-delay;
	transition-delay:		  $transition-delay;
}

@mixin transition-duration($transition-duration) {
	-webkit-transition-duration: $transition-duration;
	-moz-transition-duration:    $transition-duration;
	-ms-transition-duration:     $transition-duration;
	-o-transition-duration:      $transition-duration;
	transition-duration:		  $transition-duration;
}

@mixin animation($animation) {
	-webkit-animation: 	$animation;
	-moz-animation: 	$animation;
	-ms-animation: 		$animation;
	-o-animation: 		$animation;
	-animation: 		$animation;
}

@mixin animation-name($name) {
	-webkit-animation-name: $name;
	animation-name: $name;
}

@mixin animation-direction($direction) {
	-webkit-animation-direction: $direction;
	animation-direction: $direction;
}

@mixin animation-duration($duration) {
	-webkit-animation-duration: $duration;
	animation-duration: $duration;
}

@mixin animation-timing-function($timing-function) {
	-webkit-animation-timing-function: $timing-function;
	animation-timing-function: $timing-function;
}

@mixin animation-iteration-count($iteration-count) {
	-webkit-animation-iteration-count: $iteration-count;
	animation-iteration-count: $iteration-count;
}

@mixin animation-delay($delay) {
	-webkit-animation-delay: $delay;
	animation-delay: $delay;
}

@mixin translatey($distance) {
	-webkit-transform: translateY($distance);
	transform: translateY($distance);
}

@mixin translatex($distance) {
	-webkit-transform: translateX($distance);
	transform: translateX($distance);
}

@mixin transform($values) {
	-webkit-transform: $values;
	-ms-transform: $values;
	transform: $values;
}

@mixin rotate($value) {
	-webkit-transform: rotate($value);
	-ms-transform: rotate($value);
	transform: rotate($value);
}

@mixin transform-origin($value) {
	-webkit-transform-origin: $value;
	-moz-transform-origin: $value;
	-ms-transform-origin: $value;
	transform-origin: $value;
}

/* Border radius mixins */
@function meta-unit($number, $unit) {

	@if $unit == "px" {
		@return $number+px
	}
	@if $unit == "%" {
		@return $number+"%"
	}
	@if $unit == "em" {
		@return em($number)+px
	}
	@if $unit == "rem" {
		@return calculateRem($number)
	}
}

/* Border radius mixin */
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
    // behavior: url(/common/scripts/pie/PIE.htc);
}

// tl: top left, tr: top right, br: bottom right, bl: bottom left
@mixin border-radius-all($tl, $tr, $br, $bl, $unit) {
	@if $unit == "%" {
		-webkit-border-radius: meta-unit($tl,"%") meta-unit($tr,"%") meta-unit($br,"%") meta-unit($bl,"%");
		-moz-border-radius: meta-unit($tl,"%") meta-unit($tr,"%") meta-unit($br,"%") meta-unit($bl,"%");
		border-radius: meta-unit($tl,"%") meta-unit($tr,"%") meta-unit($br,"%") meta-unit($bl,"%");
	}

	@if $unit == 'px' {
		-webkit-border-radius: meta-unit($tl,px) meta-unit($tr,px) meta-unit($br,px) meta-unit($bl,px);
		-moz-border-radius: meta-unit($tl,px) meta-unit($tr,px) meta-unit($br,px) meta-unit($bl,px);
		border-radius: meta-unit($tl,px) meta-unit($tr,px) meta-unit($br,px) meta-unit($bl,px);
	}
}


/* Box shadow mixins */
@mixin box-shadow($horizontal, $vertical, $blur, $spread, $colour) {
    -webkit-box-shadow: $horizontal $vertical $blur $spread $colour;
    -moz-box-shadow: $horizontal $vertical $blur $spread $colour;
    -ms-box-shadow: $horizontal $vertical $blur $spread $colour;
    box-shadow: $horizontal $vertical $blur $spread $colour;
}

@mixin box-shadow-none() {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
}

@mixin inset-box-shadow($horizontal, $vertical, $blur, $spread, $colour) {
    -webkit-box-shadow: inset $horizontal $vertical $blur $spread $colour;
    -moz-box-shadow: inset $horizontal $vertical $blur $spread $colour;
    -ms-box-shadow: inset $horizontal $vertical $blur $spread $colour;
    box-shadow: inset $horizontal $vertical $blur $spread $colour;
}


/* Responsive mixins */
// @mixin max-mobile (@rules) {
//     @media (max-width: @max-mobile) {
//         @rules();
//     }
// }
@mixin max-mobile {
	@media only screen and (max-width: #{$max-mobile}) {
		@content;
	}
}
// @mixin min-tablet (@rules) {
//     @media (min-width: @min-tablet) {
//         @rules();
//     }
// }
@mixin min-tablet {
	@media only screen and (min-width: #{$min-tablet}) {
		@content;
	}
}
// @mixin max-tablet (@rules) {
//     @media (max-width: @max-tablet) {
//         @rules();
//     }
// }
@mixin max-tablet {
	@media only screen and (max-width: #{$max-tablet}) {
		@content;
	}
}
// @mixin mobile-nav (@rules) {
//     @media (max-width: @mobile-nav) {
//         @rules();
//     }
// }
@mixin mobile-nav {
	@media only screen and (max-width: #{$mobile-nav}) {
		@content;
	}
}
// @mixin min-desktop (@rules) {
//     @media (min-width: @min-desktop) {
//         @rules();
//     }
// }
@mixin min-desktop {
	@media only screen and (min-width: #{$min-desktop}) {
		@content;
	}
}
// @mixin mid-desktop (@rules) {
//     @media (max-width: @mid-desktop) {
//         @rules();
//     }
// }
@mixin mid-desktop {
	@media only screen and (max-width: #{$mid-desktop}) {
		@content;
	}
}
// @mixin small-desktop (@rules) {
//     @media (max-width: @small-desktop) {
//         @rules();
//     }
// }
@mixin small-desktop {
	@media only screen and (max-width: #{$small-desktop}) {
		@content;
	}
}
// @mixin max-desktop (@rules) {
//     @media (max-width: @max-desktop) {
//         @rules();
//     }
// }
@mixin max-desktop {
	@media only screen and (max-width: #{$max-desktop}) {
		@content;
	}
}
// Between @min-desktop and @small-desktop
// @mixin min-small-desktop (@rules) {
//     @media (min-width: @min-desktop) and (max-width: @small-desktop) {
//         @rules();
//     }
// }
@mixin min-small-desktop {
	@media only screen and (min-width: #{$min-desktop}) and (max-width: #{$small-desktop}) {
		@content;
	}
}
// Between @min-desktop and @small-desktop
// @mixin min-max-desktop (@rules) {
//     @media (min-width: @min-desktop) and (max-width: @max-desktop) {
//         @rules();
//     }
// }
@mixin min-max-desktop {
	@media only screen and (min-width: #{$min-desktop}) and (max-width: #{$max-desktop}) {
		@content;
	}
}
// // Between @min-tablet and @max-tablet
// @mixin min-max-tablet (@rules) {
//     @media (min-width: @min-tablet) and (max-width: @max-tablet) {
//         @rules();
//     }
// }
@mixin min-max-tablet {
	@media only screen and (min-width: #{$min-tablet}) and (max-width: #{$max-tablet}) {
		@content;
	}
}


/* Flexbox mixins */
@mixin flex() {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	html.no-flexbox & {
		display: block;
	}
}

@mixin flex-align($value) {
	-webkit-box-align: $value;
	-ms-flex-align: $value;
	-webkit-align-items: $value;
	align-items: $value;

	@if ($value == center) {
		html.no-flexbox & {
			[class^=span] {
				display: table-cell;
				vertical-align: middle;
				float: none;
				padding: 0 (percentage($margin/$max-width));

				img {
					width: 100%;
				}
			}
		}
	}

	@if ($value == flex-end) {
		-ms-flex-align: end;
	}
}

@mixin flex-wrap($value) {
	-ms-flex-flow: $value;
	-webkit-flex-flow: $value;
    flex-flow: $value;
}

@mixin flex-justify($value) {
	-webkit-box-pack: $value;
	-ms-flex-pack: $value;
	-webkit-justify-content: $value;
	justify-content: $value;
}

@mixin flex-length($value) {
	-webkit-box-flex: $value;
	-moz-box-flex: $value;
	-webkit-flex: $value;
	-ms-flex: $value;
	flex: $value;
}

@mixin flex-direction($value) {
	-webkit-box-direction: $value;
	-moz-box-direction: $value;
	-ms-flex-direction: $value;
	-webkit-flex-direction: $value;
	flex-direction: $value;
}

@mixin flex-order($value) {
	-moz-box-order: $value;
	-ms-flex-order: $value;
	-webkit-order: $value;
	flex-order: $value;
	order: $value;
}

@mixin align-self($value) {
	-webkit-align-self: $value;
	align-self: $value;
}


/* Form mixins */
@mixin pointer-events {
	-ms-pointer-events: none;
	pointer-events: none;
}

@mixin placeholder-color($color) {

    &::-webkit-input-placeholder {
       color: $color;
    }

    &:-moz-placeholder { /* Firefox 18- */
       color: $color;
       opacity: 1;
    }

    &::-moz-placeholder {  /* Firefox 19+ */
       color: $color;
       opacity: 1;
    }

    &:-ms-input-placeholder {
       color: $color;
    }

}


/* Calc property mixin */
// @mixin calc($property, $value) {

// 	@if $property = 'width' {
// 		width: -moz-calc($value);
// 		width: -webkit-calc($value);
// 		width: -o-calc($value);
// 		width: calc($value);
// 	}

// 	@if $property = 'height' {
// 		height: -moz-calc($value);
// 		height: -webkit-calc($value);
// 		height: -o-calc($value);
// 		height: calc($value);
// 	}
// }
@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
  	#{$property}: -webkit-calc(#{$expression});
  	#{$property}: -o-calc(#{$expression});
  	#{$property}: calc(#{$expression});
}


/* Miscellaneous mixins */
@mixin box-sizing($size) {
	-webkit-boz-sizing: $size;
	-moz-box-sizing: $size;
	box-sizing: $size;
}

@mixin font-size($size, $line-height: ($size + 6)) {
	font-size: $size + px;
	font-size: meta-unit($size,rem);
	line-height: meta-unit($line-height,px);
	line-height: meta-unit($line-height,rem);
}

@mixin vertical-gradient($start,$end) {
	background: $end;
	background: -webkit-linear-gradient($start,$end);
	background: linear-gradient($start,$end);
}

@mixin hide-text() {
	text-indent: 110%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin cf() {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

@mixin columns($value) {
	-webkit-columns: $value;
	-moz-columns: $value;
	columns: $value;
}

// Allows for 2x images for device ratios 2+
@mixin high-res-bg-image($image, $width, $height){
	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}

// Last child no margin-bottom
@mixin last-child-no-bottom() {
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

// Mobile chevron for accordions
@mixin mobile-chevron() {
	&:after {
		content: '';
		position: relative;
		width: 6px;
		height: 6px;
		display: inline-block;
		margin-left: 10px;
		border-top: 1px solid $text-grey-light;
		border-right: 1px solid $text-grey-light;
		@include rotate(-45deg);
		@include transition(all 0.2s ease);
	}

	&.active {
		&:after {
			@include rotate(135deg);
			top: -3px;
		}
	}
}

// Cancel Transitions
@mixin cancel-transitions() {
	-webkit-transition: none !important;
	-moz-transition:    none !important;
	-ms-transition:     none !important;
	-o-transition:      none !important;
	transition:			none !important;
}

@mixin mobile-show {
    display: none;
    @include max-mobile() {
        display: block;
    }
}

@mixin mobile-hide {
    display: block;
    @include max-mobile() {
        display: none;
    }
}

/* media query mixins that use breakpoints set in constants.scss */
@mixin media($max, $min: "") {

  @if ( $min == "" ) {
    @media all and (max-width: #{$max} ){
      @content;
    }
  } @else {
    @media all and (max-width: #{$max} ) and (min-width: #{$min}) {
      @content;
    }
  }
}