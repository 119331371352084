.recipe-listing,
.standard-listing,
.categoryListing {
    @include flex();
    @include flex-wrap(wrap);
    margin-bottom: 20px;

    .recipe-item {
        margin-top: 25px;
    }
}

.recipe-item,
.listing-category {
    height: auto !important; // override js 
    padding-bottom: 68px;

    .roundel {
        position: absolute;
        @include flex();
        @include flex-align(center);
        width: 80px;
        height: 80px;
        top: -15px;
        right: 5px;
        @include border-radius(100%);
        background-color: $group-green;
        text-align: center;
        z-index: 2;
        
        h2 {
            color: $white;
            @include font-size(14,14);
            margin: 0;
            position: relative;
            top: 2px;
        }
    }

    .overlay {
        position: relative;
        margin-bottom: 20px;
    }

    .overlay,.main-link {
        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            &:hover {
                + img {
                    opacity: 0.8;
                }
            }
        }

        img {
            width: 100%;
            height: auto;
            display: block;
            @include transition(opacity 0.2s ease);
        }
    }

    .main-link {
        &:hover {
            img {
                opacity: 0.8;
            }
        }
    }

    h2 {
        @include font-size(22,26);
        font-family: $CircularBold;
        color: $red;
        margin: 0 0 15px 0;
        letter-spacing: -0.5px;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    p {
        color: $text-grey-light;
        margin-bottom: 0;
    }

    .button {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
// Tablet only
@include min-max-tablet() {
    .row {
        .recipe-item,
        .listing-category {
            width: (percentage((6*$column+10*$margin)/$max-width));
        }
    }
}
// Mobile only
@include max-mobile() {
    .row {
        .recipe-item,
        .listing-category {
            margin-top: 30px;
        }
    }
}



.listing-category {    
    a {
        &:hover {
            img {
                opacity: 0.8;
            }
        }

        img {
            margin-bottom: 20px;
        }
    }

    img {
        margin: 0;
        width: 100%;
        height: auto;
    }

    h2 {
        color: $group-green;
    }
}

.listing-heading {
        
    h2 {
        @include font-size(43);
        color: $text-grey;
        margin-bottom: 10px;
    }

    p {
        color: $text-grey-light;
    }

    .select-wrapper {
        display: inline-block;
        max-width: 270px;

        @include max-mobile() {
            max-width: none;
        }
    }
}

.categoryListing {
    padding-bottom: 30px;
    
    .listing-category {
        margin-top: 30px;
    }
}


// Recipe detail
.tags {
    width: 100%;
    font-size: 0;
    margin-bottom: 30px;

    > * {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 8px;
        margin-right: 8px;
    }

    p {
        @include font-size(16);
        margin-bottom: 8px;
    }

    .tag {
        padding: 9px 12px 8px 12px;
        font-size: 12px;
        line-height: 12px;
        color: $text-grey;
        background-color: $grey-6;
        text-transform: uppercase;
        text-decoration: none;
        @include transition(background-color 0.2s ease);

        &:hover {
            background-color: shade($grey-6,5%);
        }
    }
}

.recipe-overview {
    width: 100%;
    padding: 20px 0 40px 0;

    .wrap {
        width: 100%;
        @include flex();
        @include flex-wrap(wrap);
    }

    .details,
    .image {
        min-height: 340px;
        width: 50%;
    }

    .details {
        background-color: $group-green;
        text-align: center;
        padding: 50px;
        font-size: 0;
        @include flex();
        @include flex-wrap(wrap);
        @include flex-align(center);

        .icon {
            display: inline-block;
            vertical-align: top;
            width: (percentage(1/3));
            color: $white;
            font-size: 14px;
            line-height: 16px;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: 43px;
            padding-top: 56px;
            text-transform: uppercase;

            strong {
                display: block;
            }

            &.serves {
                background-image: url(../images/icons/Serves_Icon.png);
            }

            &.prepare {
                background-image: url(../images/icons/Prepare_Icon.png);
            }

            &.cooks {
                background-image: url(../images/icons/Cooks_Icon.png);
            }
        }
    }

    .image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;

        img {
            margin: 0;
            display: none;
        }
    }
}

// Max desktop styles
@include max-desktop() {
    .recipe-overview {
        .details {
            padding: 25px;
        }
    }
}

// Min tablet and mid desktop styles
@media (min-width: $min-tablet) and (max-width: $mid-desktop) {
    .recipe-overview {
        .details {
            .icon {
                width: 100%;
                margin: 15px 0;
            }
        }
    }
}

// Min tablet and above styles
@include min-tablet() {
    .recipe-overview {
        .wrap {
            @include flex-direction(row-reverse);
        }
    }
}

// Mobile styles
@include max-mobile() {
    .recipe-overview {
        .wrap {
            display: block;
        }

        .details,
        .image {
            width: 100%;
            min-height: 0;
        }

        .image {
            background-image: none !important; // override inline style.

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        .details {
            padding: 40px 15px;
        }
    }

    .listing-heading {
        h2 {
            @include font-size(30);
        }
    }
}