body {
	position: relative;
	width: 100%;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

* {
	@include box-sizing(border-box);
}

.inline{
	display: inline-block;
}

strong{
	font-family: $CircularBold;
	font-weight: normal;
}

ul {
	padding-left: 0;
}

img {
	 max-width: 100%;
	 height: auto;
	 vertical-align: bottom;
}

.show-in-mobile-nav {
	display: none;

	@include mobile-nav() {
		display: block;
	}
}

.show-in-tablet {
	display: none;

	@include max-tablet() {
		display: block;
	}
}

.show-in-mobile {
	display: none;

	@include max-mobile() {
		display: block;
	}
}

// Fixes issues with google maps images not showing correctly
// img[src*="gstatic.com/"], img[src*="googleapis.com/"] {
// 	max-width: none;
// }

/* Cookie Notification styles */
.cookie-notification {
	color: $white;
	background-color: $grey-2;
	display: none;
	padding: 10px;
	width: 100%;

	@include max-desktop() {
		padding: 10px 15px;
	}

	@include max-mobile() {
		padding: 10px 15px;
	}

	.row {
		@include flex();
		@include flex-align(center);

		// IE9 Fix
		html.no-flexbox & {
			[class^=span] {
				display: block;
				float: left;
				padding: 0;
			}
		}
	}

	p {
		margin: 0;
		color: $group-green;
		line-height: 1.5rem;

		@include max-mobile() {
			line-height: 1.2rem;
		}
	}

	a {
		color: inherit;
		text-decoration: underline;
	}

	.close {
		span {
			display: inline-block;
			padding: 10px 0 10px 11px;
			font-family: IcoMoon;
			cursor: pointer;
			position: relative;
			line-height: normal;

			&:before {
				content: '\e902';
				font-size: 21px;
				color: $group-green;
			}

			@include max-desktop() {
				padding-right: 0;
			}
		}
	}
}

// Infinity tracking number
.InfinityNumber {
	a {
		color: inherit;
		text-decoration: none;
		cursor: text;
	}
}