/*** Site Header Styles ***/

.page-header {
	padding: 40px 0 17px 0;
	position: relative;
	z-index: 1;
	
	@include max-desktop() {
		padding: 30px 15px 17px 15px;
		
		.row {
			position: static;
		}
	}
	
	@include mobile-nav() {
		padding: 20px 0 0 0;

		.masthead {
			padding: 0 15px 20px 15px;
		}
	}

	.row {
		> [class^=span] {
			font-size: 0;

			@include mobile-nav() {
				width: auto;
				margin-left: 0;
				margin-right: 0;

				&.text-right {
					float: right;

					> .search-form {
						display: none;
					}
				}
			}
		}
	}

	#logo {
		display: block;
		padding-top: 10px;

		img {
			display: block;
			width: 100%;
			max-width: 245px;
			height: auto;
			@include transition(opacity 0.2s ease);
			position: relative;
			top: -5px;
		}

		&:hover {
			img {
				opacity: 0.75;
			}
		}

		@include max-desktop() {
			img {
				max-width: 200px;
			}
		}

		@include small-desktop() {
			img {
				max-width: 210px;
			}
		}

		@include mobile-nav() {
			padding-top: 0;

			img {
				max-width: 150px;
			}
		}
	}

	.social {
		display: inline-block;
		vertical-align: top;
		font-size: 0;

		@include mobile-nav() {
			display: none;
		}

		a {
			text-decoration: none;
			vertical-align: top;
			display: inline-block;
			width: 30px;
			height: 30px;
			margin-left: 6px;
			background-size: 30px;
			background-repeat: no-repeat;
			background-position: center;
			@include transition(opacity 0.2s ease);

			&:hover {
				opacity: 0.7;
			}

			&.instagram {
				background-image: url(../images/icons/Instagram.png);
			}
			
			&.google {
				background-image: url(../images/icons/Instagram.png);
			}
			
			&.twitter {
				background-image: url(../images/icons/Twitter.png);
			}

			&.facebook {
				background-image: url(../images/icons/Facebook.png);
			}
		}
	}

	.link-icon {
		display: inline-block;
		vertical-align: top;
		color: $text-grey-dark;
		font-family: $CircularBold;
		text-decoration: none;
		@include font-size(16,30);
		padding-right: 40px;
		background-position: right top;
		background-repeat: no-repeat;
		background-size: 30px auto;
		@include transition(color 0.2s ease);

		&:hover {
			color: $red;
		}

		&.offers {
			background-image: url(../images/icons/Get_Offers_Icon.png);
			margin-left: 38px;

			@include mobile-nav() {
				display: none;
			}
		}

		&.store {
			background-image: url(../images/icons/CC_Pin.png);
			padding-bottom: 8px;
			margin-left: 30px;
		}
		
		@include small-desktop() {
			&.offers,
			&.store {
				margin-left: 15px;				
			}

			&.store {
				height: 40px;
			}
		}	

		@media (max-width: 880px) {
			font-size: 0;						
		}

		@include mobile-nav() {		
			&.store {
				background-size: auto 40px;
			}
		}
	}

	/* Header Nav Container */
	.nav-container {
		position: relative;
		top: -24px;

		@media (min-width: 871px) {
			display: block !important; // override responsive
		}
		
		@include mobile-nav() {
			display: none;
			width: 100%;
			clear: both;
			margin: 0;
			top: 0;
			background-color: $grey-6;
			padding: 15px 15px 5px 15px;

			.primary-nav {
				display: block !important; // override original common.js
				float: none;
			}
		}
	}

	&.persistant-nav {
		position: fixed;
		top: 0 !important;
		width: 100%;
	}

	&.persistant-nav-mobile {
		z-index: 10;
		position: fixed;
		@include transition(top 0.2s ease-in-out);
		width: 100%;

		&.mobile-visible {
			top: 0 !important;
			z-index: 10;
		}
	}
	
	p {
		margin: 0;
	}
}

.menu {
	display: none;
}

@include mobile-nav() {
	.menu {
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin-left: 10px;
		width: 40px;
		height: 40px;
		cursor: pointer;
		@include border-radius(100%);
		background-color: $group-green;
		@include transition(background-color 0.2s ease);

		&.open {
			background-color: $red;			
		}
	}
}

.menu-icon {
	height: 16px;
	width: 24px;
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	position: absolute;
	top: 50%;
	margin-top: -8px;
	left: 50%;
	margin-left: -12px;
	
	span {
		height: 2px;
		width: 100%;
		display: block;
		background-color: $white;
		@include transition(all 0.2s ease);
		
		&.middle {
			margin: 5px 0;
		}
	}
}