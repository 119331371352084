section.story-listing-pod {
    padding: 60px 0;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    p.head-content {
        @include font-size(20,26);
        margin-bottom: 35px;

        @include max-mobile(){
            font-size: 18px;
        }
    }

    p {
        color: #737373;
        margin: 0 0 25px;
        font-family: $CircularBook;
    }

    h2 {
        color: #f7323f;
        @include font-size(32,36);
        font-family: $CircularBold;
        margin-bottom: 35px;
        letter-spacing: -2px;
    }
    span.caption {
        @include font-size(14,26);
        text-align: right;
        font-style: italic;
        display: inline-block;
        width: 100%;
        font-family: $CircularBook;
        padding-right: 15px;
        @include max-mobile(){
            font-size: 14px;
        }
    }

    img {
        @include max-tablet() {
            width: 100%;
        }
        @include max-mobile() {
            width: 100%;
        }
    }


    @include max-tablet(){
        padding: 50px 30px;
    }

    @include max-mobile(){
        padding: 50px 15px;
    }

    [class^=span]{
        @include max-tablet() {
            width:97.5%;
        }

        @include max-mobile(){
            width:97.5%;
        }

        &.span10 {
            @include max-tablet() {
                margin-left: 1.25%;
            }
        }

        &.span7 {
            @include max-tablet() {
                margin: 0 1.25%;
            }
        }

        &.span3 {

        }
    }
}