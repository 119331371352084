h2.email {
    margin-bottom: 30px !important; // override rich-text last-child no margin-bottom
}

.contact-details {
    .detail {
        padding-bottom: 25px;
        margin-bottom: 20px;
        border-bottom: 1px solid $grey-3;

        @include last-child-no-bottom();

        h3 {
            padding-left: 40px;
            background-repeat: no-repeat;
            background-size: 30px;
            background-position: left center;
            font-size: 18px;
            line-height: 30px;

            &.location {
                background-image: url(../images/icons/Pencil_Icon.png);
            }

            &.tel {
                background-image: url(../images/icons/Tel_Icon.png);
            }
        }

        address,p {
            color: $text-grey-light;
        }

        address {
            font-style: normal;
        }
    }
}

// Tablet styles
@include max-tablet() {
    .row {
        .contact-details,
        .contact {
            width: (percentage((12*$column+22*$margin)/$max-width));
        }
    }
}

// Tablet only styles
@include min-max-tablet() {
    .contact-details {
        &:after {
            @include cf();
        }
        .detail {
            float: left;
            width: 50%;
            padding-bottom: 0;
            border: none;
            margin-bottom: 30px;
        }
    }
}

// Mobile only styles
@include max-mobile() {
    .row {
        form.contact {
            width: 100%;
        }
    }

    .contact-details {
        width: 100%;
    }
}

// Contact form responsive styles
.contact-form {
  form {
    margin-bottom: 0;
  }

  [class^=span].submit-area {
    float: right;

    @include small-desktop() {
      text-align: right;
    }
  }

  @media (min-width: 981px) and (max-width: $max-desktop) {
    .button {
      padding-left: 15px;
      padding-right: 15px;
      line-height: 15px;
      font-size: 15px;
    }
  }

  @media (min-width: 981px) and (max-width: 1023px) {
    .button {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .recaptcha-validation {
    color: $red;
  }
}