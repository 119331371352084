/*** SLIDER / CAROUSEL Styles ***/

.slider {
	opacity: 0;
	@include transition(opacity 0.3s ease);

	&.slick-initialized {
		opacity: 1;

		&:before {
			display: none;
		}
	}

	.slide {
		&:focus {
			outline: 0;
		}

		.row {
			@include flex();
			@include flex-align(center);
		}
	}
}

/* Arrows */
/* Left / Right arrows */
.arrows {
	position: absolute;
	top: (calc(50% - 40px));
	left: 0;
	margin-top: -10px;
	width: 100%;
	z-index: 1;

	@media (max-width: 1350px) {
		display: none;
	}

	.arrow {
		width: 50px;
		height: 50px;
		position: absolute;
		cursor: pointer;
		@include border-radius(100%);
		background-color: rgba(0,0,0,0.2);
		@include transition(background-color 0.2s ease);

		&:before {
			content: '';
			position: absolute;
			left: 20px;
			top: 17px;
			width: 12px;
			height: 12px;
			border-top: 3px solid $white;
		}

		&:hover {
			background-color: rgba(0,0,0,0.5);
		}

		&.prev {
			left: -80px;

			&:before {
				border-left: 3px solid $white;
				@include rotate(-45deg);
			}
		}

		&.next {
			right: -80px;

			&:before {
				left: 15px;
				top: 17px;
				border-right: 3px solid $white;
				@include rotate(45deg);
			}
		}
	}
}

/* DOTS Paging */
.slick-dots {
	font-size: 0;

	li {
		position: relative;
		display: inline-block;
		width: 15px;
		height: 15px;
		margin: 0 5px 0 0;
		padding: 0;
		cursor: pointer;

		button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 15px;
			height: 15px;
			cursor: pointer;
			color: transparent;
			border: none;
			@include border-radius(100%);
			outline: none;
			background-color: $white;
		}

		&.slick-active {
			button {
				background-color: $red;
			}
		}
	}
}

.carousel-wrapper {
	margin-bottom: 20px;

	+ .container {
		padding-top: 0;
	}
}

.slick-slider {
	height: 75vh;
	min-height: 550px;
	margin-bottom: 0;
	position: relative;
	overflow: hidden;

	.slick-track {
		height: 100%;
	}

	.slick-slide {
		&:focus {
			outline: 0;
		}
	}

	.slide-type-a {
		position: relative;

		> a,
		> .bg-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center top;
			background-repeat: no-repeat;
			will-change: transform;
			z-index: 1;

			picture,
			img {
				width: 100%;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				object-fit: cover;
				object-position: center;
				font-family: 'object-fit: cover; object-position: center;';
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0.1+100 */
				background: -moz-linear-gradient(left,  rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 70%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left,  rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.1) 70%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right,  rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.1) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#1a000000',GradientType=1 ); /* IE6-9 */
				z-index: 2;
			}

			&.no-overlay {
				&:before {
					display: none;
				}
			}
		}

		> .bg-image {
			height: (calc(100% - 40px));
		}

		&.no-foreground-image {
			> .bg-image {
				height: 100%;
			}
		}

		h1,h2,p,.button {
			position: relative;
		}

		h1,h2,p {
			color: $white;
		}

		h1,h2 {
			@include font-size(70,80);
			letter-spacing: -2px;
			font-family: $CircularBold;
			margin-bottom: 10px;

			+ .button {
				margin-top: 15px;
			}
		}

		p {
			letter-spacing: -0.5px;
			margin-bottom: 20px;
			@include font-size(24);
		}

		&.full-height {
			> .bg-image {
				height: 100%;
			}

			.slide-content {
				top: 0;
			}
		}
	}

	.flex {
		@include flex();
		@include flex-wrap(wrap);
		@include flex-align(center);
		height: 100%;
		z-index: 2;

		html.no-flexboxlegacy & {
			height: 658px;
		}

		@include max-tablet() {
			html.no-flexboxlegacy & {
				height: 550px;
			}
		}

		@include max-mobile() {
			html.no-flexboxlegacy & {
				height: 350px;
			}
		}


	}

	.slide-content {
		top: -20px;

		html.no-flexboxlegacy & {
			top: 0;
		}
	}

	.text-right {
		@include align-self(flex-end);
		bottom: -20px;
	}

	.dots {
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 60px;
		z-index: 1;
	}
}

.slick-slider-outer {
	opacity: 0;
	@include transition(opacity 0.5s ease);

	&.slick-initialized {
		opacity: 1;

		+ .preloader {
			display: none;
		}
	}
}

// Loader
.preloader {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	background-color: $white;
	background-image: url(../images/icons/CC_Loading.gif);
	background-repeat: no-repeat;
	background-size: auto;
	background-position: center;
}


@include max-desktop() {
	.slick-slider {
		.slide-type-a {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.dots {
		padding-left: 20px;
	}
}

@include small-desktop() {
	.slick-slider {
		.slide-type-a {
			h1,h2 {
				@include font-size(50,56);
			}
		}
	}
}

@include min-max-tablet() {
	.slick-slider {
		.flex {
			.text-right {
				img {
					max-width: 75%;
				}
			}
		}
	}
}

@include max-tablet() {

	.slick-slider {
		height: 550px;
		min-height: 350px;
		margin-bottom: 0;

		.row {
			[class^=span] {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
				float: none;
			}
		}

		.slide-type-a {
			padding: 0 $tablet-mobile-padding;

			> .bg-image {
				height: 100%;
			}

			&.full-height,
			&.no-foreground-image {
				> .bg-image {
					height: 100%;
				}

				.flex {
					@include flex-align(center);
				}
			}
		}

		.flex {
			@include flex-align(flex-end);

			.slide-content {
				top: auto;
				width: 60%;
			}

			.text-right {
				padding: 0 15px;

				img {
					margin: 0 auto;
				}
			}
		}

		.dots {
			bottom: 100px;
		}
	}
}

// @include min-tablet() {
	.slick-slider {

		.slide-type-a {
			overflow: hidden;

			> a,
			> .bg-image {
				width: 108%;
				@include transition(left 8s linear);

				&:before {
					opacity: 0;
					@include transition(opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1s);
				}
			}

			h1,h2,
			.text-right,
			.button,
			p {
				opacity: 0;
				@include transition(all 1s cubic-bezier(0.39, 0.575, 0.565, 1) 1s);
			}

			h1,h2 {
				top: 50px;
			}

			.text-right {
				top: -50px;
				@include transition-delay(2s);
			}

			.button,
			p {
				top: 30px;
				@include transition-delay(3s);
				@include transition-duration(0.5s);
			}

			p {
				@include transition-delay(2s);
			}

			&.animate {
				> .bg-image {
					left: -8%;

					&:before {
						opacity: 1;
					}
				}

				h1,h2,
				.text-right,
				.button,
				p {
					top: 0;
					opacity: 1;
				}

				.button {
					&.main-ani-finished {
						@include transition-delay(0s);
						@include transition-duration(0.3s);
					}
				}
			}

			&:not(.animate) {
				* {
					@include cancel-transitions();
				}
			}

			&.single-slide {
				> .bg-image {
					@include cancel-transitions();
					left: 0;
					width: 100%;
				}
			}
		}
	}
// }

@include max-mobile() {
	.slick-slider {
		height: 350px;

		.slide-type-a {
			h1,h2 {
				@include font-size(32,38);
				margin-bottom: 10px;
			}

			p {
				@include font-size(16,20);
			}

			.button {
				padding: 4px 11px;
				font-size: 12px;
			}

			.flex {
				.text-right {
					width: 75%;
					padding-left: 0;
					padding-right: 0;
					margin-right: auto;
					margin-left: auto;
				}
			}

			&.animate {
				> .bg-image {
					left: 0;
				}
			}
		}
	}
}

//This is an adjustment for the liser so that if there is products so the base line of the slide will still be at 350px
//rather than 310px due to the height calc So keeping it consistent for slides without products

.home, .slide-products {

	@include max-tablet() {
		.carousel-wrapper {

			min-height: 390px;
		}

		.slick-slider {
			height: 390px;

			.slide-type-a > .bg-image {
				height: calc(100% - 40px);
			}
		}

	}
}