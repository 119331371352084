section.standard-content-main + section.document-downloads-pod {
    padding: 0 0 110px;

    @include max-tablet() {
        padding: 0 30px 110px 30px;
    }
}

section.document-downloads-pod {
    padding: 110px 0;

    @include max-tablet(){
        padding: 50px 15px;
    }


    [class^=span] {

        h2 {
            color: #f7323f;
            @include font-size(32,36);
            font-family: $CircularBold;
            margin-bottom: 35px;
            letter-spacing: -2px;
        }

        @include max-tablet() {
            width: 97.5%;
        }

        &.span5.offset1,
        &.span10.offset1{
            @include max-tablet() {
                width: 97.5%;
                margin: 0 1.25%;
            }

            @include max-mobile() {
                width: 97.5%;
                margin: 0 1.25%;
            }
        }
        &.span5 {
            @include max-tablet() {
                width: 97.5%;
            }
            @include max-mobile() {
                width: 97.5%;
            }
        }

    }

    .span5 {
        &:first-child {
            margin-left: (percentage(($column+3*$margin)/$max-width));
            @include max-tablet {
                margin-left:1.25%;
            }
        }

        &:last-child {
            .dl-pod {
                @include max-tablet {
                    margin-bottom: 0;
                }
            }
        }
    }

    .dl-pod {
        display: inline-block;
        width: 100%;
        @include flex();
        @include flex-align(stretch);
        //@include flex-wrap(wrap);
        @include max-tablet {
            margin-bottom: 20px;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }

        .text-container {
            padding: 22px 20px;
            background-color: #ebebeb;
            display: inline-block;
            float: left;
            width: (percentage((10*$column+16*$margin)/$max-width));

            @media(max-width: 1180px) {
                width: (percentage((9*$column+16*$margin)/$max-width));
            }
            @include max-tablet() {
                width: (percentage((11*$column+18*$margin)/$max-width));
            }
            @include max-mobile() {
                padding: 15px 20px;
            }
            p {
                margin-bottom: 0;
                @include font-size(20, 26);
                font-style: $CircularBook;
                color: #545454;
                @include max-mobile() {
                    font-size:16px;
                    line-height: 1.375rem;
                }
            }

        }

        .dl-image-container {
            padding: 20px 30px;
            background-color:#f7323f;
            display: inline-block;
            width: 100px;
            text-align: center;
            float: left;
            vertical-align: middle;

            img {
                vertical-align: middle
            }
        }
    }
}
