$purple: #664397;
$lightPurple: #efecf4;
$white: #FFFFFF;
$black: #000000;
$red: #f7323f;

$tiny: 420px;
$small: 568px;
$medium: 768px;
$large: 980px;
$huge: 1200px;
$mega: 1400px;

@function GenerateSnow($n) {
    $value: '#{random(2000)}px #{random(2000)}px #FFF';
    @for $i from 2 through $n {
        $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
    }
    @return unquote($value);
}

$StarsSmall: GenerateSnow(700);
$StarsMed: GenerateSnow(200);
$StarsBig: GenerateSnow(100);


.seasonal-popup {
  width: 100vw;
  height: 100vh;
  background: rgba($white, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity .5s ease;
  transition-delay: 2s;
  counter-reset: graphicimage;

  &.-is-active {
    display: flex;
    opacity: 1;
  }

  .button {
    transition: background .25s;

    &:hover {
      background: rgba($red, 0.75);
    }

    &:disabled {
      background: rgba($red, 0.75);
    }
  }
}

.seasonal-popup__inner {
  position: relative;
  width: 100%;
  max-width: 725px;
  margin: 0 20px;
  text-align: center;
  background: url('/rebrand/common/images/christmas/christmas-cadbury-bg.png') center bottom no-repeat $purple;
  background-size: cover;
  box-shadow: 4px 4px 4px rgba($black, 0.1);
  z-index: 20;
  overflow: hidden;

  @include media($small){
    margin: 10px;
  }

  &.-during,
  &.-finish {
    opacity: 1;
    transform: scale(1);
  }

  &.-finish {

    @include media($small){
      align-self: flex-start;
    }
  }
}

.seasonal-popup__close {
  display: flex;
  align-items: center;
  position: absolute;
  background: transparent;
  top: 14px;
  right: 14px;
  color: rgba($white, 0.7);
  transition: color .25s ease;

  @include media($small){
    top: 8px;
  }

  &:hover {
    color: $white;

    span:before {
      color: $white;
    }
  }

  span {
    display: inline-block;
    padding-right: 10px;
    font-family: IcoMoon;
    cursor: pointer;
    position: relative;
    line-height: normal;

    &:before {
      content: '\e902';
      font-size: 14px;
      color: rgba($white, 0.7);
      transition: color .25s ease;
    }
  }
}

.seasonal-popup__header {
  background: $purple;
  border-radius: 4px;
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: left;
  color: $white;

  @include media($small){
    padding: 15px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.seasonal-popup__header-text {
  position: relative;
  z-index: 3;
}

.seasonal-popup__header-image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  z-index: 3;

  @include media($small){
    display: none;
  }
}

.seasonal-popup__header-santa {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translateX(-100%);
  animation: animSanta 25s linear infinite;
  animation-delay: 3s;
}

.seasonal-popup__intro-image {
  margin-bottom: 1.5rem;

  @include media($small){
    margin-bottom: 1rem;
  }
}

.seasonal-popup__content {
  margin: 50px;
  padding: 30px 30px 0;
  background: $white;
  border-radius: 4px;
  position: relative;

  @include media($medium) {
    max-height: calc(100vh - 70px); // minus top margin and bottom margin
    overflow-y: scroll;
  }

  @include media($small) {
    margin: 40px 10px 10px;
    padding: 20px 15px 0;
  }

  .-finish & {
    padding: 30px 30px 0;

    @include media($small){
      padding: 10px 10px 0;
    }
  }

  > p {
    font-size: 16px;

    &:first-of-type {
      color: $red;
      font-weight: bold;
      font-size: 22px;

      @include media($small){
        font-size: 18px;
      }
    }
  }
}

.seasonal-popup__content-text {

  p:nth-child(n+2){

    @include media($medium){
      display: none;
    }
  }
}

.seasonal-popup__form {
  padding: 30px;
  background: $lightPurple;
  border-radius: 4px;

  @include media($small){
    padding: 10px;
  }

  input {

    @include media($small){
      padding: 10px;
    }
  }

  > p {
    font-size: 16px;

    &:first-of-type {
      color: $red;
      font-weight: bold;
      font-size: 22px;

      @include media($small){
        font-size: 18px;
      }
    }
  }

  .validation-message {
    margin-top: 5px;
    color: red;
    display: none;
  }

  input.input-validation-error ~ label {

    &:before {
      border: 1px solid red;
    }
  }

  input.input-validation-error ~ .validation-message {
    display: block;
  }
}

.seasonal-popup__form--thankyou {
  display:none;
}

.seasonal-form__errors {
  display:none;
  color: $red;
}

.seasonal-popup__sponsor {
  position: relative;
  width: auto;
  height: 50px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-flow: row-reverse wrap;
  align-items: center;
  justify-content: space-evenly;

  @include media($medium){
    flex-flow: column-reverse nowrap;
    height: 50px;
  }

  .-finish & {
    height: 85px;

    @include media($medium){
      height: 100px;
      padding: 10px 0;
    }
  }

  img + img {
    margin-right: 20px;

    @include media($medium){
      margin: 0 0 10px;
    }
  }
}

.seasonal-popup__graphic {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  background: $purple;
  border-radius: 4px;
  padding: 30px;

  @include media($medium){
    padding: 15px;
  }

  p {
    color: $white;
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0.5px;

    @include media($medium){
      font-size: 18px;
    }
  }

  .button {
    margin: 0;
  }
}

.seasonal-popup__graphic-image {
  margin-bottom: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: relative;

  img {
    opacity: 0;
    transform: scale(0);
    will-change: transform;
  }

  &[data-presents="0"]{

      .seasonal-popup__graphic-item:nth-of-type(1) {

        img {
          animation: presentPop .3s linear forwards;
          animation-delay: .7s;
          opacity: 1;
        }

        &:before {
          animation: pulse .5s ease-in-out;
          animation-delay: .8s;
        }
      }

      .seasonal-popup__graphic-item:nth-of-type(2) {

        img {
          animation: presentPop .3s linear forwards;
          animation-delay: 1.7s;
          opacity: 1;
        }

        &:before {
          animation: pulse .5s ease-in-out;
          animation-delay: 1.8s;
        }
      }

      .seasonal-popup__graphic-item:nth-of-type(3) {

        img {
          animation: presentPop .3s linear forwards;
          animation-delay: 2.7s;
          opacity: 1;
        }

        &:before {
          animation: pulse .5s ease-in-out;
          animation-delay: 2.8s;
        }
      }
    }

  &[data-presents="1"]{

    .seasonal-popup__graphic-item img {
      display: block;
      transform: scale(0);
      opacity: 0;
    }
  }

  &[data-presents="2"]{

    .seasonal-popup__graphic-item:nth-of-type(1) {
      background: none;

      span {
        visibility: hidden;
      }

      img {
        display: block;
        transform: scale(1);
        opacity: 1;
      }
    }

    .seasonal-popup__graphic-item:nth-of-type(2) {

      img {
        transform: scale(0);
        opacity: 1;
      }
    }
  }

  &.-is-active {

    img {
      opacity: 1;
    }

    &[data-presents="1"]{

      .seasonal-popup__graphic-item:nth-of-type(1) {

        img {
          animation: presentPop .3s linear forwards;
          animation-delay: .75s;
        }

        &:before {
          animation: pulse .5s ease-in-out;
          animation-delay: .85s;
        }
      }
    }

    &[data-presents="2"]{

      .seasonal-popup__graphic-item:nth-of-type(2) {

        img {
          animation: presentPop .3s linear forwards;
          animation-delay: .75s;
        }

        &:before {
          animation: pulse .5s ease-in-out;
          animation-delay: .85s;
        }
      }
    }
  }
}

.seasonal-popup__graphic-item {
  width: 39px;
  height: 55px;
  margin-right: 35px;
  position: relative;
  background: url('/rebrand/common/images/christmas/empty-present.png') center center no-repeat;

  span {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -5%);

    &:after {
      color: rgba($white, 0.4);
      font-weight: bold;
      counter-increment: graphicimage;
      content: counter(graphicimage);
    }
  }

  &:before {
    content: '';
    width: 39px;
    height: 55px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('/rebrand/common/images/christmas/empty-present.png') center center no-repeat;
  }

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 2px;
    background: rgba($white, 0.1);
    right: -38px;
    top: 50%;
    transform: translate(-50%, 50%);
  }

  &:last-of-type {
    margin-right: 0;

    &:after {
      content: none;
    }
  }
}

.snow1 {
  display: block;
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $StarsSmall;
  animation: animStar 50s linear infinite;
  border-radius: 50%;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $StarsSmall;
    border-radius: 50%;
  }
}

.snow2 {
  display: block;
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $StarsMed;
  animation: animStar 100s linear infinite;
  border-radius: 50%;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $StarsMed;
    border-radius: 50%;
  }
}

.snow3 {
  display: block;
  width: 4px;
  height: 4px;
  background: transparent;
  box-shadow: $StarsBig;
  animation: animStar 150s linear infinite;
  border-radius: 50%;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $StarsBig;
    border-radius: 50%;
  }
}

.seasonal-form__toggle {
  display: none;
  background: #f7323f;
  margin: 0 auto;
  font-weight: bold;
  color: #FFF;

  @include media($medium){
    display: block;
  }

  .button {

    &:after {
        transform: translate(3px, -25%) rotate(132deg);
    }
  }
}

.seasonal-form {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -7px;

  @supports (display: grid){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    margin: 0;
  }

  @include media($medium){
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: height .25s ease;
    margin-top: 15px;
  }

  @include media($small){
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10px;
  }

  &.-is-active {

    @include media($medium){
      max-height: 2000px;
    }
  }

  .button {
    grid-column: span 2;
    margin: 0 auto;

    @include media($small){
      grid-column: span 1;
    }
  }

  .input-validation-error {
    padding-right: 45px;
  }
}

.seasonal-form__item {
  justify-content: center;
  flex: 0 0 48%;
  margin: 5px;

  @supports (display: grid){
    margin: 0;
  }

  @include media($small){
    margin-bottom: 10px;
    flex: 0 0 auto;
  }

  input {

    @include media($small){
      margin: 0;
    }

    &::placeholder {
      color: rgba($black, 0.2);

      @include media($small){
        font-size: 12px;
      }
    }
  }

  &.-full-width {
    flex: 0 0 100%;
    text-align: left;

    @supports (display: grid){
      grid-column: span 2;
    }

    @include media($small){
      grid-column: span 1;
      flex: 0 0 auto;
    }
  }

  .checkbox-wrapper {
    margin-bottom: 0;

    label {
      line-height: 40px;

      @include media($small){
        line-height: initial;
        padding-left: 30px;
        margin-right: 0;
        margin-bottom: 0;
        font-size: 14px;
      }

      &:before {
        background: $white;

        @include media($small){
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}


//bunny positioning
.base {
  width: 150px;
  height: 126px;
  cursor: pointer;
  background: url('/rebrand/common/images/christmas/present.png') 100% 100% no-repeat transparent;
  transition: opacity .25s ease, transform .25s ease;
  position: absolute;
  z-index: 999;
  animation: wiggle 5s linear infinite;
  display: none;

  &.-has-been-clicked {
    display: none;
  }
}

.present-1 {
  display: block;
  bottom: 0;
  transform: translateY(25%);
}
.present-3 {
  display: block;
  right: 0;
  transform: translateX(25%) rotate(-75deg)
}

.present-4 {
  display: block;
  left: 0;
  transform: translateX(-25%) rotate(75deg);
}

.present {
  max-width: 361px;
  max-height: 518px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  z-index: 10;
  display: none;

  @include media($small){
    max-width: 250px;
  }

  &.-is-active {
    display: block;
  }
}


@keyframes animStar {
    from {
        transform: translateY(-2000px);
    }
    to {
        transform: translateY(0px);
    }
}

@keyframes animSanta {
    0% {
      transform: translate(-100%, 50%) rotate(5deg);
    }
    80% {
      transform: translate(545px, -100px) rotate(5deg);
    }
    100% {
      transform: translate(545px, -100px);
    }
}

@keyframes presentPop {
    0% {
      transform: scale(0);
      opacity: 0;
      visibility: hidden;
    }
    66% {
      transform: scale(1.2);
      opacity: 1;
      visibility: visible;
    }
    100% {
      transform: scale(1);
      opacity: 1;
      visibility: visible;
    }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.6);
    opacity: 0;
  }
}