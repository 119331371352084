section.final {
	&.full-width {
		.row {
			max-width: none;
		}
	}

	.row {
		@include flex();
		@include flex-align(center);
		@include flex-wrap(wrap);

		[class^=span] {
			width: 50%;
			margin-left: 0;
			margin-right: 0;
			height: 380px;
			position: relative;
			@include flex();
			@include flex-align(center);
		}

		&.recipe {
			@include flex-direction(row-reverse);
			@include flex-align(initial);

			[class^=span] {
				min-height: 540px;
			}

			&.video {
				[class^=span] {
					min-height: 390px;

					&.video {
						background-color: $black;
					}
				}
			}
		}
	}

	.pod {
		&.cross-site {
			&.green {
				background-color: $group-green;
			}

			&.red {
				background-color: $red;
			}

			article {
				padding-left: 60px;

				@include small-desktop() {
					padding-left: 30px;
				}

				> * {
					position: relative;
				}
			}

			.bg-image {
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				background-size: cover;
				background-position: right center;
				background-repeat: no-repeat;
			}

			&.overlay {
				.bg-image {
					opacity: 0.23;
				}
			}

			h2,p {
				color: $white;
			}

			h2 {
				@include font-size(34);
				font-family: $CircularBold;
				margin-bottom: 15px;
				max-width: 285px;
			}

			p {
				max-width: 340px;
			}
		}

		&.recipe {
			background-color: $grey-1;
			padding: 30px 65px 30px 60px;

			&.white {
				background-color: $white;
			}

			@include small-desktop() {
				padding: 30px;
			}

			h2 {
				@include font-size(50,56);
				color: $red;
				font-family: $CircularBold;
				letter-spacing: -2px;
			}

			p {
				color: $text-grey;
				margin-bottom: 20px;
			}

			ul {
				list-style-type: none;
				margin-bottom: 35px;

				li {
					position: relative;
					padding-left: 18px;

					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 8px;
						width: 6px;
						height: 6px;
						border-top: 2px solid $red;
						border-right: 2px solid $red;
						@include rotate(45deg);
					}

					a {
						color: $red;
						@include font-size(18);
						text-decoration: none;
						position: relative;

						&:hover {
							text-decoration: underline;
						}						
					}
				}
			}
		}
	}

	.recipe.video {
		.pod.recipe {
			h2 {
				@include font-size(34);
				margin-bottom: 15px;
			}
		}
	}

	.recipe-image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		text-align: center;
		padding-top: 30px;
		padding-bottom: 30px;
		@include flex-justify(center);

		.title {
			color: $white;
			display: block;
			@include font-size(60,66);
			font-family: $CircularBold;
			position: relative;
			padding-left: 15px;
			padding-right: 15px;
		}

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: $black;
			opacity: 0.09;
		}

		a {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			z-index: 1;
		}
	}

	.promo-area {
		@include flex();
		@include flex-align(center);
		@include flex-wrap(wrap);

		[class^=span] {
			min-height: 600px;
		}

		.promo {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}

		article {
			width: 65%;
			margin: 0 auto;

			p {
				color: $white;
				@include font-size(18,26);
				margin: 30px 0 25px 0;
			}
		}

		.promo-image {
			padding: 30px;

			img {
				margin: 0 auto;
			}
		}
	}
}

@include max-desktop() {
	section.final {
		padding: 30px $tablet-mobile-padding;
	}
}

// Tablet and mobile
@include max-tablet() {
	section.final {
		.row {
			[class^=span] {
				width: 100%;
				padding-top: 30px;
				padding-bottom: 30px;
			}

			&.recipe {
				display: block;

				[class^=span] {
					display: block;
					min-height: 0;

					&.recipe-image {
						min-height: 300px;		
						@include flex();
						@include flex-align(center);				
						@include flex-justify(center);				
					}
				}

				&.video {
					[class^=span] {
						height: auto;
						min-height: 0;  
					}
				}
			}
		}

		.pod {
			&.cross-site {
				&.green {
					@include flex-justify(center);

					article {
						padding-right: 30px;
						text-align: center;
					
						h2,p {
							max-width: none;
						}
					}
				}
			}

			&.recipe {
				h2 {
					@include font-size(40,40);
				}				
			}
		}

		.recipe-image {
			text-align: center;

			&:before {
				opacity: 0.2;
			}
			
			.title {
				@include font-size(40,46);
			}
		}

		.promo-area {
			[class^=span] {
				min-height: 400px;
			}
		}
	}	
}

// Tablet only
@include min-max-tablet() {
	section.final {
		.promo-area {
			.promo-image {
				img {
					height: 100%;
					width: auto;
				}
			}
		}
	}
}

// Mobile
@include max-mobile() {
	section.final {
		.row {
			[class^=span] {
				display: block;
				min-height: 0;
				height: auto;
				padding: 20px 5px 25px 5px;				
			}

			&.recipe {
				.recipe-image {
					@include flex();
					@include flex-align(center);
					@include flex-justify(center);				
				}
			}
		}

		.pod {
			&.cross-site {
				.bg-image {
					display: none;
				}

				article {

					h2 {
						@include font-size(24,30);
					}
				}
			}

			p {
				font-size: 15px;
			}
		
			&.cross-site,
			&.recipe {
				article {
					padding-left: 15px;
					padding-right: 15px;
				}
			}

			&.recipe {
				h2,p {
					margin-bottom: 15px;
				}

				h2 {
					@include font-size(24,30);
					letter-spacing: -1px;
				}				
			
				ul {
					margin-bottom: 20px;
					
					li {
						a {
							font-size: 14px;
						}
					}
				}
			}
		}

		.promo-area {
			.promo {
				padding: 40px 25px;

				article {
					width: 100%;

					img {
						max-width: 170px;
					}

					p {
						@include font-size(15,22);
					}
				}
			}

			.promo-image {
				padding: 30px;
			}
		}
	}
}