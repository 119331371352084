.welcome,
.location-list .content {
    background-color: $grey-2;

    &.container { 
        padding-top: 0;
        padding-bottom: 0;

        @include max-desktop() {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .row {
        @include flex();
        @include flex-wrap(wrap);
    
        [class^=span] {
            padding-top: 40px;
            padding-bottom: 30px;
        }

        // Need to change the span selectors class names if we can
        .store-name {
            position: relative;
            padding-right: 15px;

            @include min-desktop() {
                background-color: $grey-3;                
                
                &:before {
                    content: '';
                    position: absolute;
                    width: 400%;
                    left: -400%;
                    height: 100%;
                    top: 0;
                    background-color: $grey-3;
                }
            }
        }

        .flex {
            @include flex();
            @include flex-wrap(wrap);

            .address,.opening-hours {
                width: 50%;
                padding-right: 15px;
            }

            @include min-tablet() {
                .opening-hours {
                    padding-left: 15px;
                    padding-right: 0;
                }
            }
        }
    }

    h1,
    h2 {
        color: $group-green;
        font-family: $CircularBlack;
        @include font-size(28,33);
    }

    h1 {
        letter-spacing: -1px;        
    }

    h2 {
        @include font-size(18,21);
        margin-bottom: 12px;
    }

    p {
        color: $text-grey;
    }

    .operating {
        background-position: left center;
        background-repeat: no-repeat;

        &.open {
            background-image: url(../images/icons/Open.png);
            background-size: 44px auto;
            padding-left: 70px;
        }

        p {
            margin-bottom: 0;
        }

        time {
            color: $group-green;
            font-family: $CircularBlack;
            padding-left: 17px;
            background-image: url(../images/icons/Clock.png);
            background-repeat: no-repeat;
            background-position: left center;
            background-size: auto 15px;

            strong {
                font-family: $CircularBlack;
            }
        }
    }

    address {
        font-style: inherit;
        color: $text-grey;
        margin-bottom: 18px;
    }

    .tel,
    .sc-goal {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        color: $group-green;
        font-family: $CircularBlack;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 10px;
        padding-left: 40px;
        background-image: url(../images/icons/Tel_Icon.png);
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 30px;
    }

    .sc-goal {
        padding-bottom: 8px;
        background-image: url(../images/icons/CC_Pin.png);
        
        a {
            color: inherit;
            text-decoration: none;
            font-family: $CircularBook;
            border-left: 1px solid $grey-4;
            margin-left: 3px;
            padding-left: 6px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .opening-hours {
        h2 {
            margin-bottom: 5px;
        }

        p {
            width: 100%;
            display: inline-block;
            margin: 0;
            padding-top: 1px;
            padding-bottom: 1px;
            border-bottom: 1px solid $grey-5;

            span {
                float: right;
            }
        }

        > div {
            p {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

@media (min-width: $min-desktop) and (max-width: $max-desktop) {
    .welcome {
        .row {
            .store-name {
                padding-left: 15px;
            }

            .flex {
                padding-right: 15px;
            }
        }
    }
}

// Small Desktop
@include min-small-desktop() {
    .welcome {
        .row {
            [class^=span] {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                padding-left: 25px;
                padding-right: 25px;
            }

            .store-name {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .flex {
                .address {
                    padding-right: 25px;
                }

                .opening-hours {
                    padding-right: 0;
                }
            }
        }

        h1 {
            margin-bottom: 20px;
        }
    }
}

// Min-desktop styles
@include min-desktop() {
    .welcome {
        .row {
            .flex {
                .address,.opening-hours {
                    h2 + div {
                        display: block !important; // override js
                    }
                }
            }
        }
    }
}

// Tablet & Mobile

@include max-tablet() {
    .welcome {
        background-color: $white;

        &.container {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            [class^=span] {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                padding-top: 10px;
                padding-bottom: 0;
            }

            .flex {
                .address,.opening-hours {
                    width: 100%;
                    padding-top: 0;
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }

        h1 {
            margin-bottom: 15px;
            @include font-size(21,26);
        }

        h1,h2 {
            letter-spacing: -1px;
        }

        h2 {
            @include font-size(16);
            color: $text-grey-light;
            padding: 10px 15px;
            background-color: $grey-6;
            width: 100%;
            display: block;
            position: relative;
            cursor: pointer;

            @include mobile-chevron();
        }

        p,
        .operating time {
            @include font-size(14);
        }

        .operating time {
            background-size: auto 13px;
        }

        .store-name {
            padding-left: $tablet-mobile-padding;
            padding-right: $tablet-mobile-padding;
        }

        .tel,
        .sc-goal {
            background-image: none;
            padding-left: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .sc-goal {
            margin-top: 15px;            
        }

        .address,
        .opening-hours {
            h2 {
                margin-bottom: 0;
            }
        }

        .address {
            border-bottom: 1px solid $grey-7;
            .top {
                > div {
                    display: none;
                    padding: 15px $tablet-mobile-padding;
                }
            }

            address {
                margin-bottom: 5px;
            }

            .sc-goal {
                display: none;
            }
        }

        .opening-hours {
            > div {
                display: none;
                padding: 11px $tablet-mobile-padding 15px $tablet-mobile-padding;
            }
        }
    }
}

@include max-mobile() {
    .welcome,
    .location-list .content {
        .operating {
            &.open {
                padding-left: 55px;
            }
        }
    }
}