.homepage-offers {
    &.container {
        padding-bottom: 50px;
    }

    h1 {
        color: $red;
        font-family: $CircularBold;
        @include font-size(30);
        margin-bottom: 35px;
    }

    .costcutter-promo-offers {
        font-size: 0;
    }
}


// Tablet and mobile
@include max-tablet() {
    .homepage-offers {
        padding-left: $tablet-mobile-padding;
        padding-right: $tablet-mobile-padding;

        .costcutter-promo-offers {
            .offer-item {
                width: 50%;
            }
        }
    }
}

@include max-mobile() {
    .homepage-offers {
        padding-top: 20px;
        
        .costcutter-promo-offers {
            .offer-item {
                width: 100%;
            }
        }
    }
}