//inital overlay and popup
body {

    @include transition(all 0.3s ease);

    .easter-popup-modal {
        visibility: hidden;
        left: -9999px;
        opacity: 0;
        @include transition(all 0.3s ease);
    }

    &.easter-popup {

        &:after {
            content: '';
            background-color: rgba(255, 255, 255, 0.7);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }

        .easter-popup-modal {
            position: fixed;
            z-index: 3;
            max-width: 774px;
            height: 480px;
            background-size: 100%, 100%;
            background-repeat: no-repeat;
            @include transition (all 0.3s ease);
            top: 50%;
            width: 100%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            opacity: 0;
            left: 50%;
            visibility: visible;

            @include max-tablet() {
                max-width: 569px;
                height: 354px;
            }

            @include max-mobile() {
                width: 320px;
                height: 250px;
            }

            &.start {
                background-image: url(../images/easter/lightbox.png);
                opacity: 1;

                @include max-mobile() {
                    background-size: 135%;
                    background-position: 50% 25%;
                }

            }

            &.end {
                background-image: url(../images/easter/lightbox_success.png);
                opacity: 0;

                @include max-mobile() {
                    background-size: 135%;
                    background-position: 50% 25%;
                }

            }

            .cta-container {
                width: 100%;
                display: inline-block;
                text-align: center;
                position: absolute;
                bottom: 12%;

                @include max-tablet() {
                    bottom: 8%;
                }

                @include max-mobile() {
                    bottom: 5%;
                }

                a {
                    margin: 0 auto;
                    width: auto;
                    display: inline-block;

                    @include max-tablet() {
                        width: 75%;
                    }

                    @include max-mobile() {
                        width: 35%;
                    }
                }
            }
        }

    }

    &.easter-popup-end {
        &:after {
            content: '';
            background-color: rgba(255, 255, 255, 0.7);
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
        }

        .egg-top,
        .egg-bottom {
            &.success {
                visibility: visible;
                opacity: 1;
                left: 50%;
                transition-delay: 1.8s;
                animation-delay: 1.8s;
            }

            @include max-mobile() {
                width: 320px;
            }
        }
    }

    .easter-popup-modal-end {
        position: fixed;
        z-index: 1000;
        max-width: 774px;
        height: 480px;
        background-size: 100%, 100%;
        background-repeat: no-repeat;
        @include transition (all 0.3s ease);
        top: 50%;
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        opacity: 0;
        visibility: hidden;
        left: -9999px;

        @include max-tablet() {
            max-width: 569px;
            height: 354px;
        }

        @include max-mobile() {
            width: 320px;
            height: 250px;
        }

        &.success {
            background-image: url(../images/easter/lightbox_success.png);
            opacity: 1;
            transition-delay: 1.8s;
            left: 50%;
            visibility: visible;
            background-repeat: no-repeat;
            background-size: 100%, 100%;

            @include max-mobile() {
                background-size: 135%;
                background-position: 40% 25%;
            }
        }

        .cta-container {
            width: 100%;
            display: inline-block;
            text-align: center;
            position: absolute;
            bottom: 12%;

            @include max-tablet() {
                bottom: 8%;
            }

            @include max-mobile() {
                bottom: 5%;
            }

            a {
                margin: 0 auto;
                width: auto;
                display: inline-block;

                @include max-tablet() {
                    width: 75%;
                }
                @include max-mobile() {
                    width: 35%;
                }
            }
        }
    }

    .egg-top {
        position: fixed;
        background-image: url(../images/easter/top_egg_shell.png);
        background-size: 100%, 100%;
        background-repeat: no-repeat;
        top: 0;
        left: -9999px;
        @include translatex(-50%);
        opacity: 0;
        visibility: hidden;
        width: 356px;
        height: 73px;
        z-index: 4;
    }

    .egg-bottom {
        position: fixed;
        background-image: url(../images/easter/bottom_egg_shell.png);
        background-size: 100%, 100%;
        background-repeat: no-repeat;
        bottom: 0;
        left: -9999px;
        @include translatex(-50%);
        opacity: 0;
        visibility: hidden;
        width: 379px;
        height: 86px;
        z-index: 4;

        @include max-mobile() {
            height: 73px;
        }
    }

    //bunny positioning
    .base {
        cursor: pointer;
        background-size: 100%, 100%;
    }
    .bunny-1 {
        position: absolute;
        background-image: url(../images/easter/bunny_1.png);
        width: 126px;
        height: 118px;
        background-repeat: no-repeat;
        z-index: 999;
        top: -117px;

    }
    .bunny-2 {
        position: absolute;
        background-image: url(../images/easter/bunny_2.png);
        width: 198px;
        height: 125px;
        background-repeat: no-repeat;
        z-index: 999;
        top: -124px;
    }
    .bunny-3 {
        position: absolute;
        background-image: url(../images/easter/bunny_3.png);
        width: 127px;
        height: 214px;
        background-repeat: no-repeat;
        right: 0;
        z-index: 999;
    }

    .bunny-4 {
        position: absolute;
        background-image: url(../images/easter/bunny_4.png);
        width: 127px;
        height: 214px;
        background-repeat: no-repeat;
        left: 0;
        z-index: 999;
    }

    .success-egg {
        background-size: 100%, 100%;
        background-repeat: no-repeat;
        @include transition (all 0.3s ease);
        top: 50%;
        width: 415px;
        -webkit-transform: translate(-50%, -50%) translate3d(0, 0, 0);
        -moz-transform: translate(-50%, -50%) translate3d(0, 0, 0);
        transform: translate(-50%, -50%) translate3d(0, 0, 0);
        opacity: 0;
        left: 50%;
        height: 505px;
        background-image: url(../images/easter/easter-egg.png);
        position: fixed;
        z-index: -1;

        @include max-tablet() {
            max-width: 528px !important;
        }

        @include max-mobile() {
            max-width: 280px !important;
        }

        &.success {
            animation: shake 1.8s cubic-bezier(.36, .07, .19, .97) both;
            backface-visibility: hidden;
            perspective: 1000px;
            z-index: 2;
        }
    }

    @keyframes shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0) translate(-50%, -50%);
        }

        20%, 80% {
            transform: translate3d(2px, 0, 0) translate(-50%, -50%);
        }

        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0) translate(-50%, -50%);
        }

        40%, 60% {
            transform: translate3d(4px, 0, 0) translate(-50%, -50%);
        }

        40% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @-webkit-keyframes shake {
        10%, 90% {
            -webkit-transform: translate3d(-1px, 0, 0) translate(-50%, -50%);
        }

        20%, 80% {
            -webkit-transform: translate3d(2px, 0, 0) translate(-50%, -50%);
        }

        30%, 50%, 70% {
            -webkit-transform: translate3d(-4px, 0, 0) translate(-50%, -50%);
        }

        40%, 60% {
            -webkit-transform: translate3d(4px, 0, 0) translate(-50%, -50%);
        }

        40% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @-moz-keyframes intro-logo-reverse {
        10%, 90% {
            -moz-transform: translate3d(-1px, 0, 0) translate(-50%, -50%);
        }

        20%, 80% {
            -moz-transform: translate3d(2px, 0, 0) translate(-50%, -50%);
        }

        30%, 50%, 70% {
            -moz-transform: translate3d(-4px, 0, 0) translate(-50%, -50%);
        }

        40%, 60% {
            -moz-transform: translate3d(4px, 0, 0) translate(-50%, -50%);
        }

        40% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }

    }

    ///first section styling

    .story-listing-pod, .page-wrapper {
        .base{

            &.bunny-1 {
                bottom: 0 !important;
                top: auto !important;
            }

            &.bunny-2 {
                bottom: 0 !important;
                top: auto !important;
            }
        }
    }

    .carousel-wrapper + .container {
        .base {
            bottom: 0 !important;
            top: auto !important;
        }

    }

}
