.offer-item {
    display: inline-block;
    vertical-align: top;
    width: (percentage(1/3));
    padding: 0 20px;
    margin-bottom: 50px;
    text-align: center;

    .item-share {
        margin-top: 20px;

        a {
            text-decoration: none;
            margin: 0 2px;
            @include transition(opacity 0.2s ease);

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &.leaflet {
        padding-top: 10px;

        .button {
            margin-top: 30px;
            @include font-size(16);
        }

        @media (min-width: 769px) and (max-width: 865px) {
            text-align: left;

            .button {
                @include font-size(14);
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.offer-terms {
    p {
        margin-top: 30px;
        margin-bottom: 0;
        color: $text-grey-light;
        @include font-size(12,16);
    }
}

#offer-listing {
    .listing-heading {
        padding-top: 0;
        
        h2 {
            color: $red;
        }
    }

    .offers {
        font-size: 0;

        .offer-item {
            margin: 50px 0 0 0;
            width: 25%;
        }
    }

    .offer-terms {
        padding-top: 80px;
        padding-bottom: 30px;
    }
}


// Tablet styles
@include max-tablet() {
    #offer-listing {
        .offers {
            .offer-item {
                width: 25%;
            }
        }
    }
}

// Mobile styles
@include max-mobile() {
    #offer-listing {
        .listing-heading {
            [class^=span] {
                text-align: left;
            }

            h2 {
                margin-bottom: 20px;
            }

            .select-wrapper {
                width: 100%;
                max-width: none;
                margin-bottom: 0;
            }
        }

        .offers {
            .offer-item {
                width: 100%;
                margin: 30px 0 0 0;
            }
        }
    }
}