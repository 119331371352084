.tasting-notes {
    padding: 60px 0;

    .wrap {
        padding: 40px;
        background-size: cover;
        background-position: right center;
        background-repeat: no-repeat;
    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.5+0,0.1+100 */
            background: -moz-linear-gradient(left,  rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(0,0,0,0.5) 0%,rgba(0,0,0,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#1a000000',GradientType=1 ); /* IE6-9 */
        }
    }

    article {
        max-width: 500px;
        position: relative;
        z-index: 1;
    }

    h2,p {
        color: $white;
    }

    h2 {
        font-family: $CircularBold;
        letter-spacing: -0.5px;
        margin-bottom: 0;
    }

    p {
        margin: 20px 0;
    }
}
// Mobile only
@include max-mobile() {
    .tasting-notes {
        .wrap {
            padding: 20px;
        }
    }
}