// WFFM styles
.wffm-form {
    legend {
        @include font-size(20);
        font-family: $CircularBold;
        margin-bottom: 20px;
        padding-top: 30px;
        color: $text-grey;
    }
    
    .field-border {
        float: left;
        width: (calc(50% - 15px));
        margin-right: 15px;
        margin-bottom: 20px;

        &:nth-child(even) {
            margin-left: 15px;
            margin-right: 0;
        }

        .field-title {
            color: $text-grey;
            @include font-size(18);

        }
        
        .field-required {
            color: $red;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 0;
        }
    }

    input {
        margin-top: 0;
    }

    .form-submit-border {
        margin-top: 20px;
    
        input[type=submit] {
            @extend .button.green;
        }
    }

    input[type=radio],
    input[type=checkbox] {
        + label {
            padding-left: 42px;
            min-height: 34px;
            line-height: 34px;
            position: relative;
            font-size: 18px;
            line-height: 30px;
            
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background-color: $grey-1;
                @include box-sizing(border-box);
                width: 30px;
                height: 30px;
            }
        }
    }

    input[type=radio] {
        + label {
            &:before {
                @include border-radius(100%);
            }
        }        

        &:checked + label {
            &:before {
                background-color: $group-green;
                border: 8px solid $grey-1;
            }
        }
    }

    input[type=checkbox] {
        &:checked + label {
            &:before {
                background-image: url(../images/icons/Checked.png);
                background-size: 15px auto;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }
}

.field-validation-error {
    margin-top: 5px;
    color: $red;

    span {
        @include font-size(14);
    }
}

// Mobile styles
@include max-mobile() {
    .wffm-form {
        .field-border {
            width: 100%;
            margin-right: 0;

            &:nth-child(even) {
                margin-left: 0;
            }
        }
    }
}