/*** Site Footer Styles ***/

.page-footer {
	padding: 60px 0;
	
	@include max-desktop() {
		padding: 30px 20px;
	}
	
	@include max-mobile() {
		padding: 20px 15px;
	}

	.list {
		h2 {
			color: $group-green;
			@include font-size(18,20);
			font-family: $CircularBold;
			margin-bottom: 8px;
		}

		ul {
			list-style-type: none;
			margin-bottom: 0;

			li {
				margin-bottom: 6px;

				a {
					color: $text-grey-light;
					text-decoration: none;
					@include transition(color 0.2s ease);

					&:hover {
						color: $red;
					}
				}
			}
		}
	}

	.copyright {
		float: right;

		.logo {
			width: 225px;
			height: auto;
			margin-bottom: 25px;
		}

		.social {
			display: none;
		}

		p,a {
			color: $text-grey-light;
			@include font-size(12);
			margin-bottom: 0;
		}

		a {
			text-decoration: none;
			@include transition(color 0.2s ease);

			&:hover {
				color: $red;
			}
		}
	}
}

// Min tablet to mid desktop styles
@media (min-width: 681px) and (max-width: $mid-desktop) {
	.page-footer {
		.row { 
			padding-right: (percentage(1/3));
			@include flex();
			@include flex-wrap(wrap);

			.list {
				width: (percentage((6*$column+10*$margin)/$max-width));
				margin-bottom: 15px;
			}

			.copyright {
				position: absolute;
				top: 0;
				right: (percentage($margin/$max-width));
				max-width: (percentage((4*$column+6*$margin)/$max-width));
			}
		}
	}
}

// Min tablet styles
@media (min-width: 681px) {
	.page-footer {
		.row {
			.list {
				ul {
					display: block !important; //override js
				}
			}
		}
	}
}

// Mobile styles
@media (max-width: 680px) {
	.page-footer {
		padding-top: 30px;
		padding-bottom: 30px;

		.row {
			[class^=span] {
				width: (percentage((12*$column+22*$margin)/$max-width));
				text-align: center;
				margin-bottom: 5px;
			}
			
			.list {
				h2 {
					@include mobile-chevron;
					cursor: pointer;

					&:after {
						border-color: $group-green;
					}
				}

				ul {
					display: none;
				}
			}

			.copyright {
				margin-top: 20px;

				img {
					width: 150px;
					margin-bottom: 15px;
				}
			}
		}
	}
}