.service-list,
.icon-list {
    font-size: 0;
    margin-right: 30px;
    margin-bottom: 20px;

    div,
    .icon {
        display: inline-block;
        vertical-align: top;
    }

    .icon {
        margin:0 5px 5px 0;
        width: 40px;
        height: 40px;
        @include border-radius(100%);
        opacity: 0.5;
        background-position: center;
        background-size: auto 40px;
        background-repeat: no-repeat;
        @include transition(all 0.2s ease);

        &.active,
        &:hover {
            opacity: 1;
        }

        &.alcohol {
            background-image: url(../images/icons/service-icons/cc-service-icons_alcohol.png);   
        }

        &.bakery {
            background-image: url(../images/icons/service-icons/cc-service-icons_bakery.png);
        }

        &.butcher {
            background-image: url(../images/icons/service-icons/cc-service-icons_butcher.png);
        }

        &.cards {
            background-image: url(../images/icons/service-icons/cc-service-icons_cards.png);
        }

        &.car-repairs {
            background-image: url(../images/icons/service-icons/cc-service-icons_carrepairsmot.png);
        }

        &.car-wash {
            background-image: url(../images/icons/service-icons/cc-service-icons_carwash.png);
        }

        &.cashback {
            background-image: url(../images/icons/service-icons/cc-service-icons_cashback.png);
        }

        &.cash-machine {
            background-image: url(../images/icons/service-icons/cc-service-icons_cashmachine.png);
        }

        &.confectionery {
            background-image: url(../images/icons/service-icons/cc-service-icons_confectionery.png);
        }

        &.dairy {
            background-image: url(../images/icons/service-icons/cc-service-icons_dairy.png);
        }

        &.deli-counter {
            background-image: url(../images/icons/service-icons/cc-service-icons_delicounter.png);
        }

        &.dry-cleaning {
            background-image: url(../images/icons/service-icons/cc-service-icons_dry-cleaning.png);
        }

        &.dvd-rental {
            background-image: url(../images/icons/service-icons/cc-service-icons_dvd.png);
        }

        &.fish {
            background-image: url(../images/icons/service-icons/cc-service-icons_fish.png);
        }

        &.food-to-go {
            background-image: url(../images/icons/service-icons/cc-service-icons_foodtogo.png);
        }

        &.frozen {
            background-image: url(../images/icons/service-icons/cc-service-icons_frozen.png);
        }

        &.fruit-and-veg {
            background-image: url(../images/icons/service-icons/cc-service-icons_fruitandveg.png);
        }

        &.health-lottery {
            background-image: url(../images/icons/service-icons/cc-service-icons_healthlottery.png);
        }

        &.hot-drinks {
            background-image: url(../images/icons/service-icons/cc-service-icons_hotcoffeetea.png);
        }

        &.hot-food {
            background-image: url(../images/icons/service-icons/cc-service-icons_hotfoods.png);
        }

        &.household {
            background-image: url(../images/icons/service-icons/cc-service-icons_household.png);
        }

        &.household-fuel {
            background-image: url(../images/icons/service-icons/cc-service-icons_householdfuels.png);
        }

        &.local-produce {
            background-image: url(../images/icons/service-icons/cc-service-icons_localproduce.png);
        }

        &.meat {
            background-image: url(../images/icons/service-icons/cc-service-icons_meat.png);
        }

        &.top-up {
            background-image: url(../images/icons/service-icons/cc-service-icons_mobiletopup.png);
        }

        &.news-mags {
            background-image: url(../images/icons/service-icons/cc-service-icons_newsandmags.png);
        }

        &.parking {
            background-image: url(../images/icons/service-icons/cc-service-icons_parking.png);
        }

        &.paypoint {
            background-image: url(../images/icons/service-icons/cc-service-icons_paypoint.png);
        }

        &.petrol-station {
            background-image: url(../images/icons/service-icons/cc-service-icons_petrolstation.png);
        }

        &.pharmacy {
            background-image: url(../images/icons/service-icons/cc-service-icons_pharmacy.png);
        }

        &.photocopying {
            background-image: url(../images/icons/service-icons/cc-service-icons_photocopying.png);
        }

        &.postoffice {
            background-image: url(../images/icons/service-icons/cc-service-icons_postoffice.png);
        }

        // logos
        &.lottery {
            background-image: url(../images/icons/service-icons/Lottery.png);
        }

        &.ups {
            background-image: url(../images/icons/service-icons/UPS.png);
        }

        /* TODO: Required logos*/
        &.collect {
            background-image: url(../images/icons/service-icons/Collect_Icon.png);
            width: 100px;
        }

        &.hermes {
            background-image: url(../images/icons/service-icons/Hermes_Icon.png);
            width: 100px;
        }

        &.oyster {
            background-image: url(../images/icons/service-icons/Oyster_Icon.png);
            width: 100px;
        }

        &.payzone {
            background-image: url(../images/icons/service-icons/Payzone_Icon.png);
            width: 31px;
        }

        &.western-union {
            background-image: url(../images/icons/service-icons/WU_Icon.png);
            width: 55px;
        }

        &.logo {
            @include border-radius(0);

            &.active,
            &:hover {
                opacity: 1;
            }
        }
    }
}

.facilities {
    background-color: $grey-6;
    padding: 30px 0 20px 0;

    @include max-desktop() {
        padding-left: $tablet-mobile-padding;
        padding-right: $tablet-mobile-padding;
    }

    .row {
        [class^=span] {
            > * {
                display: inline-block;
                vertical-align: top;
            }

            h2 {
                display: none; 
            }
        }
    }

    .service-detail {
        width: 500px;
        height: 70px;
        position: relative;

        .detail {
            @include last-child-no-bottom();
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            @include transition(opacity 0.2s ease);

            &.active {
                opacity: 1;
            }
        }

        .name {
            margin-top: -5px;
            margin-bottom: 5px;
            color: $red;
            font-family: $CircularBlack;
            @include font-size(18);
        }
        
        p {
            color: $text-grey;
            @include font-size(14);
        }
    }
}

// Min-desktop styles
@include min-desktop() {
    .service-list {
        display: inline-block !important; // override js
    }
}

@include max-tablet() {
    .facilities {
        padding: 0;

        .row {
            [class^=span] {
                width: 100%;
                margin-left: 0;
                margin-right: 0;

                h2 {
                    @include font-size(16);
                    color: $text-grey-light;
                    padding: 10px 15px;
                    margin-bottom: 0;
                    background-color: $grey-6;
                    width: 100%;
                    display: block;
                    position: relative;
                    font-family: $CircularBlack;
                    border-top: 1px solid $grey-7;

                    &:after {
                        content: '';
                        position: relative;
                        width: 6px;
                        height: 6px;
                        display: inline-block;
                        margin-left: 10px;
                        border-top: 1px solid $text-grey-light;
                        border-right: 1px solid $text-grey-light;
                        @include rotate(-45deg);
                        @include transition(all 0.2s ease);
                    }

                    &.active {
                        &:after {
                            @include rotate(135deg);
                            top: -3px;
                        }
                    }
                }

                .service-list,
                .service-detail {
                    display: none;
                }

                .service-list {
                    padding: 0 15px 15px 15px;
                    margin: 0;
                    
                    .icon {
                        margin-bottom: 10px;
                        background-color: $red;

                        &.logo {
                            background-color: transparent;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}