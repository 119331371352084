.store-finder-area {
    &.container {
        padding-bottom: 0;

        @include max-tablet() {
            padding-top: 30px;
        }
    }

    .finder-rich-text {
        > * {
            &:last-child {
                margin-bottom: 30px;
            }
        }
    }
}

.find-options {
    @include flex();
    @include flex-wrap(wrap);

    [class^=span] {
        width: 50%;
        margin-left: 0;
        margin-right: 0;
        padding: 30px 30px 40px 50px;
        background-color: $grey-6;

        @include last-child-no-bottom();

        @include small-desktop() {
            padding-left: 30px;
        }

        &.expand {
            width: 50%;          

            @include max-tablet() {
                width: 100%;
            }  
        }

        &.intro {
            display: none;
        }
    }

    h3 {
        @include font-size(28,40);
        position: relative;
        margin-top: 0;
    }

    .house {
        @include max-tablet() {
            padding-bottom: 0;
        }

        h3 {
            padding-left: 50px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 40px;
                height: 40px;
                background-color: $red;
                background-image: url(../images/icons/House.png);
                background-size: 17px auto;
                background-position: center;
                background-repeat: no-repeat;
                @include border-radius(100%);
            }
        }
    }

    .location {
        padding-right: 50px;

        @include small-desktop() {
            padding-left: 30px;
        }

        h3 {
            padding-left: 40px;

            &:before {
                content: '';
                position: absolute;
                top: 3px;
                left: 0;
                width: 30px;
                height: 38px;
                background-image: url(../images/icons/CC_Pin.png);
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 30px auto;
            }
        }
    }

    .button {
        min-width: 180px;
        text-align: center;        
    }

    .message {
        display: none;
        color: $red;
        margin-top: 15px;
        @include font-size(14);
    }

    .suggestions {
        margin-top: 15px;

        ol {
            margin-bottom: 0;

            li {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    @include transition(color 0.2s ease);

                    &:hover {
                        color: $red;
                    }
                }
            }
        }
    }
}

// Find options responsive
@include small-desktop() {
    .find-options {
        [class^=span],
        .location {
            padding-left: 30px;
        }
    }
}

@include max-tablet() {
    .find-options {
        [class^=span] {
            &.expand {
                width: 100%;
            }
        }   

        .house {
            padding-bottom: 0;
        }
    }
}

@include max-mobile() {
    .find-options {
        padding-top: 30px;

        [class^=span] {
            padding: 20px;

            h3 {
                font-size: 20px;
                line-height: 32px;
                padding-left: 38px;
            
                &:before {
                    width: 30px;
                    height: 30px;
                }
            }

            &.house {
                padding-bottom: 0;                
            }

            &.location {
                h3 {
                    &:before {
                        width: 26px;
                        height: 33px;
                        background-size: 26px auto;
                    }
                }

                .button {
                    width: 100%;
                }
            }
        }
    }
}

.loading-wrapper {
    padding: 30px 50px;
    display: none;

    [class^=span] {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }

    img {
        margin-top: 15px;
        width: 100px;
        height: auto;
        display: none;
    }
}

// Find store form
form.find-store {
    margin-bottom: 0;
    
    fieldset > * {
        display: inline-block;
        vertical-align: top;
    }

    input {
        &[type=text] {
            max-width: 270px;
            width: (calc(100% - 190px));
        }
    }

    .button {
        margin-left: 2px;
    }

    @include mid-desktop() {
        input {
            &[type=text] {
                width: auto;
                min-width: 240px;
                margin-bottom: 10px;
            }
        }
    }

    @include max-mobile() {
        input[type=text],
        .button {
            width: 100%;
            max-width: none;
        }
    }
}

// Store locator results
.results {
    padding: 20px 30px 18px 30px;
    background-color: $group-green;

    @include max-mobile() {
        padding-left: 20px;
        padding-right: 20px;
    }

    &.hide {
        display: none;
    }

    h3,a {
        color: $white;
        margin: 0;
        line-height: 32px;
    }

    h3 {
        font-size: 26px;

        @include max-mobile() {
            font-size: 22px;
            line-height: 28px;
        }
    }

    a {
        position: relative;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        @include mobile-chevron();

        &:after {
            position: absolute;
            left: -25px;
            top: 4px;
            right: auto;
            border-color: $white;
            @include rotate(45deg);
        }
    }
}

// Store service filters
.store-filter {
    padding: 25px 30px 30px 30px;
    background-color: $grey-6;

    [class^=span] {
        &.expand {
            width: 100%;
        }
    }

    h3 {
        margin-top: 0;
        margin-bottom: 25px;
    }

    form {
        margin-bottom: 0;
    }

    .checkbox-wrapper {
        &.inline {
            width: 100%;

            span {
                width: (percentage(1/6));
                padding-right: 20px;
                margin-bottom: 15px;
                margin-right: 0;
                display: inline-block;
                vertical-align: top;

                @include small-desktop() {
                    width: 25%;
                }
            }

            label {
                padding-left: 30px;
                cursor: pointer;
                min-height: 0;

                &:before {
                    width: 20px;
                    height: 20px;
                    top: -1px;
                    background-color: $white;
                }                
            }

            input {
                margin-bottom: 0;
            }
        }
    }
}

@include min-tablet() {
    .store-filter {
        .checkbox-listing {
            display: block !important; // override mobile js binding
        }
    }
}

// Store filter responsive styles
@include max-mobile() {
    .store-filter {
        padding: 15px 20px 20px 20px;

        h3 {
            margin-bottom: 0;
            cursor: pointer;

            @include mobile-chevron();
        }

        .checkbox-wrapper {
            &.inline {
                margin-bottom: 0;

                span {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        .checkbox-listing {
            /*display: none;*/ // needed for new accordion.
            margin-top: 25px;
        }
    }
}

// Map
.map-canvas {
    height: 330px;
    width: 100%;
}

// Location listing
.location-list {
    &.container {
        padding-top: 20px;
    }

    .row {
        [class^=span] {
            margin-left: 0;
            margin-right: 0;

            &.span12 {
                width: 100%;
            }
        }
    }

    .accordion-item {
        border-bottom: 1px solid $grey-2;

        &:first-child {
            .content {
                display: block;
            }
        }

        .heading {
            padding: 20px 245px 18px 30px;
            position: relative;
            cursor: pointer;
            @include transition(background-color 0.3s ease);

            @include mobile-chevron();

            &:after {
                width: 8px;
                height: 8px;
                position: absolute;
                right: 30px;
                top: 33px;
            }
        
            h3,span {
                color: $text-grey;
            }

            h3 {
                display: inline-block;
                margin-bottom: 0;
                margin-top: 0;                
                line-height: 30px;
            }

            span {
                opacity: 0.6;
                min-width: 235px;
                position: absolute;
                right: 0;
                top: 20px;
                font-family: $CircularBlack;
                font-size: 18px;
                line-height: 30px;
                padding-left: 36px;
                padding-right: 60px;
                background-image: url(../images/icons/CC_Pin_Grey.png);
                background-size: 23px auto;
                background-position: left top;
                background-repeat: no-repeat;
            }
        }
        
        .content {
            display: none;
            padding: 25px 30px 15px 30px;
            background-color: $grey-6;

            &:after {
                @include cf();
            }

            [class^=span] {
                width: (percentage(1/3));

                &.store-name,
                &.opening-hours {
                    padding-right: 30px;
                }

                &.opening-hours {
                    > div {
                        margin-bottom: 10px;
                    }
                }
            }

            h2 {
                letter-spacing: 0;
            }

            .store-name {
                h3 {
                    display: none;
                }

                address {
                    margin-bottom: 0;
                    font-family: $CircularBlack;
                    @include font-size(18);
                    color: $group-green;
                }

                .tel {
                    margin-top: 15px;
                    margin-bottom: 15px;
                    padding-left: 70px;
                    background-position: 7px center;
                }

                .operating {
                    margin-bottom: 25px;
                }

                .button {
                    margin-bottom: 10px;
                    margin-right: 5px;
                }
            }

            .services {
                .service-list,
                .icon-list {
                    .icon {
                        opacity: 1;
                    }
                }
            }
        }

        &.open {
            .heading {
                background-color: $group-green;

                &:after {
                    border-color: $white;
                    top: 28px;
                    @include rotate(135deg);
                }

                h3 {
                    color: $white;
                }

                span {
                    color: $white;
                    background-image: url(../images/icons/CC_Pin_White.png);

                    &:after {
                        border-color: $white;
                    }
                }
            }
        }
    }
}

// Location list responsive
@include small-desktop() {
    .location-list {
        .accordion-item {
            .content {
                padding-bottom: 5px;
                
                .store-name,
                .opening-hours {
                    width: 50%;
                    margin-bottom: 20px;
                }

                .services {
                    width: 100%;
                }
            }
        }
    }
}

@include max-tablet() {
    .location-list {
        .accordion-item {
            .content {
                width: 100%;

                [class^=span] {
                    width: 100%;
                    
                    &.opening-hours,
                    &.store-name {
                        padding-right: 0;
                    }

                    &.opening-hours {
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

@include max-mobile() {
    .location-list {
        .accordion-item {
            .heading {
                padding: 20px 40px 20px 20px;

                &:after {
                    right: 22px;
                    top: 28px;
                }

                h3 {
                    font-size: 20px;
                    line-height: 24px;
                }

                span {
                    position: relative;
                    top: auto;
                    display: block;
                    margin-top: 15px;
                    min-height: 30px;
                }
            }

            .content {
                padding: 20px;

                [class^=span] {
                    &.store-name,
                    &.opening-hours {
                        padding-right: 0;
                    }

                    &.opening-hours {
                        > div {
                            margin-bottom: 0;
                        }
                    }
                }

                .store-name {
                    .tel,
                    .operating {
                        padding-left: 60px;
                    }

                    .operating {
                        p {
                            line-height: 20px;
                        }
                    }
                }

                .button {
                    width: 100%;
                    text-align: center;
                }
            }

            &.open {
                .heading {
                    &:after {
                        top: 24px;
                    }
                }
            }
        }
    
        .icon-list {
            margin-bottom: 0;
        }
    }
}