/*** Hero Banner Styles ***/

.hero-area {
	min-height: 510px;
	height: 65vh;
	background-color: $grey-8;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.3);
	}

	.row {
		@include flex();
		@include flex-align(center);
		height: 100%;

		> * {
			color: $white;
		}

		h1 {
			font-family: $CircularBold;
			@include font-size(70);
			margin-bottom: 15px;
			letter-spacing: -2px;
		}

		p {
			@include font-size(24);
			margin-bottom: 0;
		}
	}
	
	&.image-only {
		&:before {
			display: none;
		}
	}
}

@include max-desktop() {
	.hero-area {
		padding-left: $tablet-mobile-padding;
		padding-right: $tablet-mobile-padding;
	}
}

@include max-mobile () {
	.hero-area {
		min-height: 310px;
		height: 50vh;
		.row {
			h1 {
				@include font-size(40);
				letter-spacing: -1px;
			}

			p {
				@include font-size(18);
			}
		}
	}
}