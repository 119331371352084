/*** replace with project specific fonts ***/

// Potential icon/glyph set for sprites.
@font-face {
	font-family: IcoMoon;
	src: url('../fonts/icomoon.eot') format('embedded-opentype'),
		 url('../fonts/icomoon.woff') format('woff'),
		 url('../fonts/icomoon.ttf') format('truetype'),
		 url('../fonts/icomoon.svg') format('svg');
}

// TODO: MUST update fonts to use webfonts versions
// Webfonts stylesheet located in /common/fonts/
$CircularBold: 'Circular-Bold', Arial, sans-serif;
$CircularBlack: 'Circular-Black', Arial, sans-serif;
$CircularBook: 'Circular-Book', Arial, sans-serif;



body {
	font-family: $CircularBook, Arial, Helvetica, sans-serif;
}

html {
	font-size: 16px;
	font-weight: 100;
	line-height: 1.4rem;
}
 
h1, h2, h3, h4, h5, h6, p, ul, ol {
	margin-top: 0;
	margin-bottom: 1rem;
	font-weight: normal;
}
 
h1, h2, h3, h4, h5, h6 {
	margin-bottom: 1rem;
}

h1 {
	margin-bottom: 2rem;
}

h2 {
	margin-bottom: 1.5rem;
}