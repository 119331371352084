/*** STATIC BUILD ONLY **/
.jump-nav {
	position: fixed;
	bottom: -300px;
	right: 0;
	height: 280px;
	width: auto;
	width: 200px;
	overflow: auto;
	padding: 0;
	cursor: pointer;
	text-align: right;
	@include transition(bottom 0.3s ease);
	
	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: left;
		
		li {
			&:first-child {
				a {
					border: none;
					@include border-radius(5px 0 0 0);
				}
			}
		}
	}
	
	&:hover,
	&.active {
		bottom: 0 !important;
	}
	
	.tab {
		display: inline-block;
		color: $white;
		font-size: 16px;
		line-height: 16px;
		padding: 15px;
		background-color: rgba(0,173,239,0.75); // 00adef
		border-bottom: 1px solid rgba(255,255,255,0.75);
		@include border-radius(5px 0 0 0);
	}
	
	a {
		width: 100%;
		display: inline-block;
		font-size: 16px;
		line-height: 16px;
		padding: 15px;
		color: $white;
		text-decoration: none;
		background-color: rgba(0,173,239,0.75); // 00adef
		@include transition(background-color 0.3s ease);
		border-top: 1px solid rgba(255,255,255,0.75);
		
		&:hover {
			background-color: rgba(0,173,239,0.95); // 00adef
		}
	}
}

/*** END STATIC BUILD ONLY ***/


/*** Header Primary Nav ***/
.primary-nav {
	position: relative;
	text-align: right;

	@include mobile-nav() {
		text-align: center;		
	}
	
	ul {
		font-size: 0;
		margin-bottom: 0;
		
		@include mobile-nav() {
			width: 100%;
			position: relative;
			left: 0;
		}
		
		li {
			position: static;
		}
	}
	
	/* Effects immediate children only */
	> ul {
		> li {
			display: inline-block;
			vertical-align: top;
			@include font-size(16);
			margin-left: 25px;
		
			a {
				color: $text-grey-light;
				@include font-size(18);
				font-family: $CircularBold;
				text-decoration: none;
				@include transition(color 0.2s ease);

				&:hover,
				&.active {
					color: $red;
				}
			}
            
			@include small-desktop() {
				margin-left: 20px;

				a {
					@include font-size(16);
				}
			}

            @include mobile-nav() {
                display: block;
                margin: 0;

				&:last-child {
					a {
						border: none;
					}
				}

				a {
					width: 100%;
					display: block;
					padding: 10px 0;
					border-bottom: 1px solid $grey-7;
				}
                
                &.has-child {
					a {
						width: 100%;
						
						&:after {
							content: '';
							position: absolute;
							top: 50%;
							margin-top: -7px;
							right: -15px;
							background-color: transparent;
							width: 10px;
							height: 10px;
							border-top: 1px solid $white;
							border-right: 1px solid $white;
							@include rotate(45deg);
						}
					}
                }
            }
		}
	}
	
	&.hide {
		> ul {
			left: -100%;
			
			.sub {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

/* Header Primary Nav - Sub Nav */
.sub {
	position: absolute;
	left: 0;
	width: 100%;
	padding: 15px;
	visibility: hidden;
	opacity: 0;
	@include transition(all 0.3s ease);
	
	@include max-desktop() {
		padding: 15px 0;
	}
	
	.row {
		padding: 15px 0;
		background-color: $white;
	}
	
	ul {		
		li {
			display: block;
			
			&:last-child {
				padding: 0;
			}
			
			@include min-desktop() {
				display: none;
			}
		}
	}
    
    @include mobile-nav() {
        left: 100%;
        opacity: 1;
		top: 0;
		
		.row {
			background-color: transparent;
			
			[class^=span] {
				width: 100%;
				margin: 0;
			}
		}
		
		> ul > li {
			pointer-events: none;
		}
    }
}

.breadcrumbs {
	position: relative;
	margin-bottom: 40px;
	
	ul {
		margin-bottom: 10px;

		li {
			display: inline-block;
			@include font-size(14);
			margin-bottom: 10px;
			margin-right: 5px;
			color: $text-grey-light;
			
			&:after {
				content: '';
				position: relative;
				width: 6px;
				height: 6px;
				display: inline-block;
				margin-left: 5px;
				top: -1px;
				border-top: 1px solid $text-grey-light;
				border-right: 1px solid $text-grey-light;
				@include rotate(45deg);
			}

			&:last-child {
				&:after {
					display: none;
				}
			}

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	
	@include max-mobile() {
		margin-bottom: 20px;
	}
}

.side-nav {
	@include min-desktop() {
		h2 {
			display: none;
		}
	}	

	h2 {
		@include mobile-chevron();
		padding: 15px;
		margin-bottom: 15px;
		color: $text-grey;
		@include font-size(20);
		font-family: $CircularBold;
		background-color: $grey-6;
		cursor: pointer;
	}

	p {
		font-family: $CircularBold;
		color: $group-green;
		@include font-size(18);
		margin-bottom: 10px;
	}

	ul {
		list-style-type: none;

		li {
			color: $text-grey-light;
			padding: 10px 0 10px 13px;
			position: relative;
			display: block;
			border-bottom: 1px solid $grey-3;

			&:last-child {
				border-bottom: none;
			}

			&:before {
				content: '';
				position: absolute;
				width: 6px;
				height: 6px;
				left: -2px;
				top: 18px;
				display: inline-block;
				border-top: 1px solid $text-grey-light;
				border-right: 1px solid $text-grey-light;
				@include rotate(45deg);
				@include transition(border-color 0.2s ease);
			}

			&:hover {
				&:before {
					border-color: $red;
				}
			}

			a {
				color: inherit;
				text-decoration: none;
				display: block;
				@include transition(color 0.2s ease);

				&:hover {
					color: $red;
				}
			}
		}
	}

	@include min-desktop() {
		> div {
			display: block !important; // override js
		}
	}

	@include mobile-nav() {
		margin-bottom: 20px;

		ul {
			margin-bottom: 0;
		}

		> div {
			display: none;
			padding: 0 15px;
		}
	}
}

.sub-section {
	clear: both;

	&:after {
		@include cf();
	}

	.sub-section-panel {
		position: relative;
		float: left;
		margin: 30px (percentage($margin/$max-width)) 0 (percentage($margin/$max-width));
		width: (percentage((3*$column+4*$margin)/$max-width));

		img {
			// testing
			// width: 256px;
			// height: 256px;

			// release
			width: 100%;
			margin-bottom: 20px;
		}

		a {
			text-decoration: none;

			h2 {
				color: $group-green;
				@include font-size(22);
				font-family: $CircularBold;
				margin-bottom: 10px;
			}
		}

		p {
			color: $text-grey;
			@include font-size(16);
		}

		.button {
			@include font-size(16);
		}
	}

	@media (min-width: 981px) {
		@include flex();

		.sub-section-panel {
			padding-bottom: 70px;

			p {
				margin-bottom: 0;			
			}

			.button {
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
	}

	@media (min-width: $min-tablet) and (max-width: $small-desktop) {
		width: (percentage((8*$column+14*$margin)/$max-width));

		.sub-section-panel {
			float: none;
			width: 100%;
			padding-right: 0;
			padding-left: 40%;
			overflow: hidden; 

			.image-link {
				position: absolute;
				width: (calc(40% - 30px));
				top: 0;
				left: 0;

				img {
					width: 100%;
					height: auto;
					margin-bottom: 0;
				}
			}
		}
	}

	@include mobile-nav() {
		width: 100%;
	}

	@include max-mobile() {
		.sub-section-panel {
			width: (percentage((12*$column+22*$margin)/$max-width));
		}
	}
}

// Pagination
.pagination {
	padding: 40px 0;
	font-size: 0;
	text-align: center;

	@include max-mobile() {
		padding: 25px 0 40px 0;
	}

	a {
		position: relative;
		width: 25px;
		height: 30px;
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		line-height: 30px;
		font-family: $CircularBold;
		color: $text-grey;
		text-align: center;
		text-decoration: none;
		@include border-radius(4px);
		@include transition(all 0.2s ease);

		&:hover,
		&.active {
			background-color: $red;
			color: $white;
		}

		&.prev,
		&.next {
			font-size: 0;

			&:before {
				content: '';
				position: absolute;
				width: 6px;
				height: 6px;
				left: 8px;
				top: 11px;
				display: inline-block;
				border-top: 1px solid $text-grey;
				border-right: 1px solid $text-grey;
				@include rotate(45deg);
				@include transition(border-color 0.2s ease);
			}

			&:hover,
			&.active {
				&:before {
					border-color: $white;
				}
			}
		}

		&.prev {
			&:before {
				border-top: 1px solid $text-grey;
				border-left: 1px solid $text-grey;
				border-right: none;
				left: 10px;
				@include rotate(-45deg);
			}
		}
	}
}

// Sitemap
.sitemap-list {
	ul {
		li {
			margin-bottom: 0;
		}
	}

	> ul {
		> li {
			> ul {
				> li {
					margin-bottom: 15px;
				}
			}
		}
	}
}