// Widths
$column: 70;
$margin: 15;
$max-width: (1170 + $margin*2);

//1380 wide with 15px margin means $column = 85

// Breakpoints (these area defaults)
// Change per project

$base-font-size: 16;
$max-desktop: 1200px; // this is the same as $max-width, but it includes px unit.
$ipad-landscape: 1024px;
$small-desktop: 980px;
$mid-desktop: 920px;
$mobile-nav: 870px;
$min-desktop: 769px;
$max-tablet: 768px;
$min-tablet: 569px;
$max-mobile: 568px;
$tablet-mobile-padding: 15px;
// Colours

$white: #fff;
$grey-1: #ebebeb;
$grey-2: #e9e9e9;
$grey-3: #dfdfdf;
$grey-4: #a1a1a1;
$grey-5: #d0cdcc;
$grey-6: #f2f2f2;
$grey-7: #e2e2e2;
$grey-8: #7b7b7b;
$black: #000;
$text-grey: #545454;
$text-grey-light: #737373;
$text-grey-lighter: #848484;
$text-grey-dark: #4c4c4c;

$red: #f7323f;
$red-2: #c42732;

$group-green: #115740;
$group-green-dark: #004b35;
$group-green-darker: #013e2c;

$Regan-slab: 'Regan Slab W00 Ultra', Arial, Helvetica, sans-serif;
$calibri-light:'Calibri W01 Light',Arial, Helvetica, sans-serif;
$calibri-normal:'Calibri W01 Regular_904604', Arial, Helvetica, sans-serif;
$calibri-bold:'Calibri W01 Bold_904607', Arial, Helvetica, sans-serif;
$franklin-dm: 'FranklinGothicURWComDem W01 Rg', Arial, Helvetica, sans-serif;
$franklin-book: 'FranklinGothicURWComBoo W01 Rg', Arial, Helvetica, sans-serif;