.careers {
    clear: both;
    
    &:after {
		@include cf();
	}

    .item {
        &:nth-child(3n) {
			padding-right: 0;
		}

        .inner {
            background-color: $grey-1;
            padding: 20px;
            width: 100%;
        }

        .title {
            display: block;
            font-family: $CircularBold;
            color: $group-green;
            @include font-size(22); 
            margin-bottom: 15px;
            letter-spacing: -1px;
        }
    }

    .location,
    .date {
        padding-left: 33px;
        background-position: left top;
        background-repeat: no-repeat;
        margin-bottom: 15px;

        dt,dd {
            color: $text-grey;
            @include font-size(16,20);
            letter-spacing: -0.5px;
        }

        dt {
            font-family: $CircularBold;
        }

        dd {
            margin: 0;
        }
    }

    .location {
        background-image: url(../images/icons/Location_Pin_Grey.png);
        background-size: 14px auto;
    }

    .date {
        background-image: url(../images/icons/Calendar_Grey.png);
        background-size: 22px auto;
    }

    .button {
        @include font-size(16,20);
        margin-top: 10px;
    }
}

// Small Desktop styles
@include small-desktop() {
    .careers {
        .item {
            width: (percentage((4*$column+6*$margin)/$max-width));
        }
    }
}

// Tablet styles
@include max-tablet() {
    .careers {
        .item {
            margin-top: 18px;
            width: (percentage((6*$column+10*$margin)/$max-width));
        }
    }
}